import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from "../common/axios";
import Layout from '../layout/Layout';


const Home = () => {
	const [userData, setUserData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await axios.post(`/dpcurrent_user`, {}, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setUserData(userResponse.data); // Set user data using Recoil
            } catch (userError) {
                console.error("Error fetching user data:", userError);
                // Handle error in fetching user data
            }
        }
        fetchData()
    }, [])


    return(<>
        <Layout>
        <div className='app-main flex-column flex-row-fluid '>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className='app-toolbar  py-3 py-lg-6 '>
                    <div className='app-container  container-xxl d-flex flex-stack '>
                        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                               DP 시스템
                            </h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    {/* <li className="breadcrumb-item text-muted">
                                        <span className="text-muted text-hover-primary">승인도</span>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-muted">
                                        계획 작성                                         
                                    </li> */}
                                </ul>
                        </div>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                        </div>
                    </div>
                </div>
                <div className='app-content  flex-column-fluid' style={{paddingTop:0}}>
                    <div className='app-container container-xxl '> 
                    환영합니다. {userData.companyName}의 {userData.username}님.<br/>
                        이 웹시스템은 삼성중공업에서 사용되는 Unifier와 연동되는 시스템입니다. 사용 중 문의가 있으시면 담당자에게 연락주시기 바랍니다. 
                    </div>
                </div>
            </div>  
        </div>
        </Layout>
        </>);
    };
    export default Home;
    