import { useNavigate } from 'react-router-dom';
import axios from '../common/axios';

export const useLogout = () => {
  const navigate = useNavigate();

  const logout = async () => {
    try {
      await axios.get(`/dplogout`);
      navigate('/dplogin');
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return logout;
};
