import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => (
  <div className='app-default' style={{backgroundColor:"#fcfcfc", minHeight:"100vh"}} >
    <Header />
    <main>{children}</main>
    <Footer/>
  </div>
);

export default Layout;