import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import DrawingA from './pages/adrawing';
// import '@progress/kendo-theme-default/dist/all.css';
import './style.bundle.css';
import './plugins.bundle.css';
import DrawingM from './pages/mdrawing';

import DrawingAInput from './pages/adrawinginput';
import DrawingMInput from './pages/mdrawinginput';
import DrawingAList from './pages/adrawingList';
import DrawingMList from './pages/mdrawingList';
import { LicenseInfo } from '@mui/x-license-pro';
import SignIn from './login/login';
import NewPW from './login/newpw';
import ConfirmPW from './login/pwconfirm';
import ResetPW from './login/resetpw';
import SignUp from './login/signujp';
import ProtectedRoute from './common/privateRoute';
import Home from './pages/home';


LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

document.title = "삼성중공업 - DP시스템";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>

          <Route path="/dplogin" element={<SignIn />} />
          <Route path="/dpreset-password" element={<ResetPW />} />
          <Route path="/dppassword-confirmation" element={<ConfirmPW />} />
          <Route path="/dpnew-password" element={<NewPW />} />
          <Route path="/dpsignup" element={<SignUp />} />

          {/* <Route exact path="/dpsignup" component={Sing} /> */}



          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/home" element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          } />
          <Route path="/adrawing" element={
            <ProtectedRoute>
              <DrawingA />
            </ProtectedRoute>
          } />
          <Route path="/mdrawing" element={
            <ProtectedRoute>
              <DrawingM />
            </ProtectedRoute>

          } />
          <Route path="/adrawinglist" element={
            <ProtectedRoute>
              <DrawingAList />
            </ProtectedRoute>

          } />
          <Route path="/mdrawinglist" element={
            <ProtectedRoute>
              <DrawingMList />
            </ProtectedRoute>
          } />
          <Route path="/adrawinginput" element={
            <ProtectedRoute>
              <DrawingAInput />
            </ProtectedRoute>
          } />
          <Route path="/mdrawinginput" element={
            <ProtectedRoute>
              <DrawingMInput />
            </ProtectedRoute>
          } />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </RecoilRoot>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
