import React, { useState, useEffect, useRef } from 'react';
import Nav from './Nav';
import { useLogout }  from '../common/logout';

const Header = () => {

  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);


  // 상태 변수를 사용하여 배경색 상태를 관리합니다.
  const [backgroundColor, setBackgroundColor] = useState('transparent');


  // 스크롤 이벤트 핸들러를 정의합니다.
  const handleScroll = () => {
    // 현재 스크롤 위치를 가져옵니다.
    const scrollY = window.scrollY;

    // 스크롤 위치에 따라 배경색을 변경합니다.
    if (scrollY > 0) {
      setBackgroundColor('#ffffff'); // 스크롤이 아래로 내려갈 때 배경색을 흰색으로 변경
    } else {
      setBackgroundColor('transparent'); // 스크롤이 위로 올라갈 때 배경색을 투명으로 변경
    }
  };

  // 컴포넌트가 마운트될 때와 언마운트될 때 스크롤 이벤트 핸들러를 추가/제거합니다.
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [moblestatus, setmoblestatus] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setmoblestatus(window.innerWidth <= 768); // 모바일 뷰의 너비 임계값을 설정하세요
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const logout = useLogout();

  const handlePageClick = (e) => {
    if (isMobileMenuVisible) {
      e.stopPropagation(); // 이벤트 버블링 막기
      setMobileMenuVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('click', handlePageClick);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('click', handlePageClick);
    };
  }, [isMobileMenuVisible]);



  return (
    <>
      {moblestatus ?
        <>
          <div className='d-flex flex-column flex-root app-root'>
            <div className='app-page flex-column flex-column-fluid'>

            </div>
          </div>
          <div className="app-header" style={{ position: "sticky", top: 0, zIndex: 107, backgroundColor }}>
            <div className="app-container container-xxl d-flex align-items-stretch justify-content-between">

              {/* 로고 */}
              <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
                <a href="http://www.samsungshi.com/kor/default.aspx">
                  <img alt="Logo" src="/images/g1313.png" className="h-20px h-lg-30px app-sidebar-logo-default theme-light-show" />
                </a>
              </div>

              <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                <div onClick={(e) => { e.stopPropagation(); }} className={`app-header-menu app-header-mobile-drawer align-items-stretch drawer drawer-end ${isMobileMenuVisible ? 'drawer-on' : ''}`} style={isMobileMenuVisible ? { width: 250 } : {}}>
                  <div className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0" >
                    <Nav />
                  </div>
                </div>


              </div>

              <div className="d-flex align-items-center gap-2 gap-lg-3">
                <span className="btn btn-sm fw-bold btn-primary" onClick={logout}>로그아웃</span>
              </div>
            </div>
          </div>
        </>
        :
        <>
          <div className='d-flex flex-column flex-root app-root' style={{ marginBottom: 75 }}>
            <div className='app-page flex-column flex-column-fluid'>
            </div>
            <div className="app-header" style={{ position: "fixed", left: 0, right: 0, zIndex: 100, backgroundColor: backgroundColor }}>
              <div className="app-container container-xxl d-flex align-items-stretch justify-content-between">

                {/* 로고 */}
                <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
                  <a href="http://www.samsungshi.com/kor/default.aspx">
                    <img alt="Logo" src="/images/g1313.png" className="h-20px h-lg-30px app-sidebar-logo-default theme-light-show" />
                  </a>
                </div>

                <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                  <div className="app-header-menu app-header-mobile-drawer align-items-stretch">
                    <div className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0" >
                      <Nav />
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center gap-2 gap-lg-3">
                  <span className="btn btn-sm fw-bold btn-primary" onClick={logout}>로그아웃</span>
                </div>

              </div>
            </div>
          </div>
        </>
      }




    </>
  )
};

export default Header;
