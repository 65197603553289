import React, { useEffect, useState,useRef,useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  DataGridPro,GridToolbar, GridToolbarFilterButton, GridToolbarColumnsButton,GridToolbarContainer
} from '@mui/x-data-grid-pro';
import { read, utils, writeFile } from 'xlsx';
import dayjs from 'dayjs';
import _ from 'lodash';
import DownloadIcon from '@mui/icons-material/Download';

export const AFormTableList = ({ tabeldata}) => {

    const rows = tabeldata.map((v,index)=>({
      ...v, 
      id:index+1,
    }))


    const columns = [
        {field:'record_no' , headerName:"레코드 번호" , width:100, editable:false},
        {field:'DrawingDiscPD' , headerName:"공종" , width:80, editable:false, 
        type: 'singleSelect', valueOptions:["설비", "건축", "전기","제어"]},
        {field:'DPsubPD' , headerName:"상세공종" , width:80, editable:false, 
        type: 'singleSelect', valueOptions:["배관", "건축", "랙","전기", "제어","설비제어","전기제어","HVAC"]},
        {field:'DPsubPD2' , headerName:"구분" , width:80, editable:false, type: 'singleSelect', 
        valueOptions:
        ({ row }) => {
          if (!row) {
            return ["모듈", "비모듈", "-", "GAL'V", "GAL'V 외"];
          }
          return row.DPsubPD === '배관'
            ? ["모듈", "비모듈"]:
            row.DPsubPD === 'HVAC'?["GAL'V", "GAL'V 외"]
            : ["-"];
        },
        
        },
        {field:'DPAreaBPK' , headerName:"Area" , width:100, editable:false},
        {field:'DPFloor' , headerName:"층" , width:100, editable:false, },
        {field:'DrawingNo' , headerName:"도면(문서) No" , 

        width:180, editable:false},
        {
          field: 'DrawingName',
          headerName: '도면명',
          width: 350,
          editable: false,

        },
        {field:'DPRevNo' , headerName:"리비전" , width:100, editable:false},
        {field:'MDModuleNoDPK' , headerName:"모듈넘버",width:100, editable:false},
        {field:'WStart' , headerName:"기준 날짜", type: 'date',width:100, editable:false},
        {field:'VendorsDPK' , headerName:"협력업체" , width:180, editable:false },

        {field:'ReceiptPlanDate' , headerName:"계획일" , type: 'date', width:100,  editable:false},
        {field:'ReceiptActualDate' , headerName:"실적일" , type: 'date', width:100,  editable:false},

        {field:'CompletionPlanDate' , headerName:"계획일" , type: 'date', width:100, editable:false},
        {field:'CompletionActualDate' , headerName:"실적일" , type: 'date', width:100, editable:false},

        {field:'SubmissionPlanDate' , headerName:"계획일" , type: 'date', width:100, editable:false},
        {field:'SubmissionActualDate' , headerName:"실적일" , type: 'date', width:100, editable:false},
        {field:'SubmissionGap' , headerName:"GAP" , type: 'number', width:80,editable:false},

        {field:'ApprovalPlanDate' , headerName:"계획일" , type: 'date', width:100, editable:false},
        {field:'ApprovalActualDate' , headerName:"실적일" , type: 'date', width:100, editable:false},
        {field:'ApprovalGap' , headerName:"GAP" , type: 'number', width:100, editable:false},

        {field:'IssuePlanDate' , headerName:"계획일" , type: 'date', width:100, editable:false},
        {field:'IssueActualDate' , headerName:"실적일" , type: 'date', width:100, editable:false},
        {field:'IssueGap' , headerName:"GAP" , type: 'number', width:100,editable:false},
    ]


    const columnGroupingModel = [
      {
          groupId: '사전검토',
          children: [
            {
              groupId: '접수(시공사→설계사)',
              children: [{ field: 'ReceiptPlanDate' }, { field: 'ReceiptActualDate' }],
            },
            {
              groupId: '완료(설계사→시공사)',
              children: [{ field: 'CompletionPlanDate' }, { field: 'CompletionActualDate' }],
            },
          ],
        },
        {
          groupId: 'CPMS',
          children: [
            {
              groupId: '상신',
              children: [{ field: 'SubmissionPlanDate' }, { field: 'SubmissionActualDate' }, { field: 'SubmissionGap' }],
            },
            {
              groupId: '승인',
              children: [{ field: 'ApprovalPlanDate' }, { field: 'ApprovalActualDate' }, { field: 'ApprovalGap' }],
            },
          ],
        },
        {
          groupId: '도면 ISSUE',
          children: [{ field: 'IssuePlanDate' }, { field: 'IssueActualDate' }, { field: 'IssueGap' }],

        },
  ]

  const downloadExcel = () => {

    function generateHeaders(data) {
      const topHeaders = [];
      const middleHeaders = [];
      const bottomHeaders = [];
  
      // Assuming all rows have the same keys, use the first row to determine headers
      const keys = Object.keys(data[0] || {});
  
      keys.forEach(key => {
          const parts = key.split('//');
          
          // Check the number of parts and assign accordingly
          if (parts.length === 1) {
              // No '//' in key
              topHeaders.push("");
              middleHeaders.push("");
              bottomHeaders.push(parts[0]);
          } else if (parts.length === 2) {
              // One '//' in key
              topHeaders.push(parts[0]);
              middleHeaders.push("");
              bottomHeaders.push(parts[1]);
          } else if (parts.length === 3) {
              // Two '//' in key
              topHeaders.push(parts[0]);
              middleHeaders.push(parts[1]);
              bottomHeaders.push(parts[2]);
          }
      });
  
      return { topHeaders, middleHeaders, bottomHeaders };
  }
  
    const initialData = rows.map((v) => ({
      "레코드 번호":v.record_no,
      "공종":v.DrawingDiscPD,
      "상세공종":v.DPsubPD,
      "구분":v.DPsubPD2,
      "Area":v.DPAreaBPK,
      "층":v.DPFloor,
      "도면(문서) No":v.DrawingNo,
      "도면명":v.DrawingName,
      "리비전":v.DPRevNo,
      "모듈넘버":v.MDModuleNoDPK,
      "기준 날짜":v.WStart,
      "협력업체":v.VendorsDPK,

      "사전검토//접수(시공사→설계사)//계획일":v.ReceiptPlanDate,
      "사전검토//접수(시공사→설계사)//실적일":v.ReceiptActualDate,
      "사전검토//완료(설계사→시공사)//계획일":v.CompletionPlanDate,
      "사전검토//완료(설계사→시공사)//실적일":v.CompletionActualDate,

      "CPMS//상신//계획일":v.SubmissionPlanDate,
      "CPMS//상신//실적일":v.SubmissionActualDate,
      "CPMS//상신//GAP":v.SubmissionGap,

      "CPMS//승인//계획일":v.ApprovalPlanDate,
      "CPMS//승인//실적일":v.ApprovalActualDate,
      "CPMS//승인//GAP":v.ApprovalGap,

      "도면 ISSUE//계획일":v.IssuePlanDate,
      "도면 ISSUE//실적일":v.IssueActualDate,
      "도면 ISSUE//GAP":v.IssueGap,
    }));

    const { topHeaders, middleHeaders, bottomHeaders } = generateHeaders(initialData);
    const worksheet = utils.aoa_to_sheet([topHeaders, middleHeaders, bottomHeaders]);
       
    // Find and merge cells with the same value in the top header
        let currentMerge = null;
        topHeaders.forEach((header, index) => {
            if (header !== "" && (index === 0 || topHeaders[index - 1] !== header)) {
                currentMerge = { s: { r: 0, c: index }, e: { r: 0, c: index } };
            } 
            if (header !== "" && (index === topHeaders.length - 1 || topHeaders[index + 1] !== header)) {
                if (currentMerge) {
                    currentMerge.e.c = index;
                    if (!worksheet["!merges"]) worksheet["!merges"] = [];
                    worksheet["!merges"].push(currentMerge);
                }
            }
        });
         
      // Find and merge cells with the same value in the middle header
      let currentMergeMiddle = null;
      middleHeaders.forEach((header, index) => {
          if (header !== "" && (index === 0 || middleHeaders[index - 1] !== header)) {
              currentMergeMiddle = { s: { r: 1, c: index }, e: { r: 1, c: index } }; // Start of new merge range in row 1
          } 
          if (header !== "" && (index === middleHeaders.length - 1 || middleHeaders[index + 1] !== header)) {
              if (currentMergeMiddle) {
                  currentMergeMiddle.e.c = index; // End of merge range
                  if (!worksheet["!merges"]) worksheet["!merges"] = [];
                  worksheet["!merges"].push(currentMergeMiddle);
              }
          }
      });


    const keysArray = Object.keys(initialData[0] || {});
    const dataRows = initialData.map(row => keysArray.map(fieldName => row[fieldName] || ""));
    utils.sheet_add_json(worksheet, dataRows, { origin: -1, skipHeader: true });

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `승인도_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);
  };
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <Box sx={{ flexGrow: 1 }} />
        <Button startIcon={<DownloadIcon />} size="small" onClick={downloadExcel}>
                    다운로드
                  </Button>
      </GridToolbarContainer>
    );
  }

    return (
    <>
    <Box sx={{ width: '100%' }}>
      {/* <Button startIcon={<DownloadIcon />} size="small" onClick={downloadExcel}>
        다운로드
      </Button> */}
        <Box
          sx={{
            height: "70vh",
            width: '100%',
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
            '& .cold': {
              backgroundColor: '#b9d5ff91',
              color: '#1a3e72',
            },
            '& .hot': {
              backgroundColor: '#ff943975',
              color: '#1a3e72',
            },
          }}
        >
          <DataGridPro
            rows={rows}
            density="compact"
            columns={columns}
            pagination
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroupingModel}
            slots={{
              toolbar: CustomToolbar,
            }}
            getCellClassName={(params) => {
              if ((params.field === 'IssueGap' ||
              params.field === 'ApprovalGap' ||
              params.field === 'SubmissionGap' )&& params.value !== null&&params.value !== 0
              ) {
                return params.value >= 0 ? 'cold' : 'hot';
              }
            }}
          />

        </Box>
        </Box>
    </>
      );
};