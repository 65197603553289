import React, { useState,useEffect } from 'react';

const date = new Date()
const year = date.getFullYear()

const Footer = () => {
    return(
        <div id="kt_app_footer" className="app-footer" >
        <div className="app-container container-xxl d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
            <div className="text-dark order-2 order-md-1">
                <span className="text-muted fw-semibold me-1">{year}&copy;</span>
                <a href="http://www.samsungshi.com/kor/default.aspx" target="_blank" className="text-gray-800 text-hover-primary">삼성중공업</a>
            </div>
        </div>
    </div>
    )
}

export default Footer;