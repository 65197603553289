import React, { useEffect, useState, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons, GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';
import { randomId } from '@mui/x-data-grid-generator';
import { read, utils, writeFile } from 'xlsx';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import _ from 'lodash';
import axios from "../axios";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import ButtonGroup from '@mui/material/ButtonGroup';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

dayjs.extend(isSameOrBefore);

// Check if dates are in the correct order
const areDatesValid = (firstDate, secondDate) => {
  if (!firstDate || !secondDate) {
    // If either date is missing, validation fails
    return true;
  }
  // Both dates exist, check their order
  return dayjs(firstDate).isSameOrBefore(dayjs(secondDate));
};


export const AFormTableInput = ({ tabeldata, projectCode }) => {

  const [rows, setRows] = React.useState(tabeldata.map((v, index) => ({
    ...v,
    id: index + 1,
  })));

  console.log(rows)

  useEffect(() => {
    setRows(tabeldata.map((v, index) => ({
      ...v,
      id: index + 1,
    })))
  }, [tabeldata])


  const [rowModesModel, setRowModesModel] = React.useState({});
  let navigate = useNavigate();

  ////////////////유효성
  const StyledBox = styled(Box)(({ theme }) => ({
    height: "70vh",
    width: '100%',
    '& .Mui-error': {
      backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
      color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
    },
  }));


  ///////////////////



  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const handleRowEditStop = (params, event) => {
    if (params?.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };


  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };


  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = (newRow) => {
    // Define the required fields and their error messages

    const date = new Date(newRow.ReceiptActualDate);
    const date2 = new Date(newRow.CompletionActualDate);
    const date3 = new Date(newRow.SubmissionActualDate);
    const date4 = new Date(newRow.ApprovalActualDate);
    const date5 = new Date(newRow.IssueActualDate);


    if (isNaN(date.getTime()) && isNaN(date2.getTime()) && isNaN(date3.getTime()) && isNaN(date4.getTime()) && isNaN(date5.getTime())) {
      throw new Error(`레코드 저장 중 에러 발생: 실적 날짜 중 하나는 필수적으로 입력되어있어야합니다.`);
    }


    // If all checks pass, update the row
    const updatedRow = newRow.WStart === null ? { ...newRow, isNew: false, WStart: newRow.MDSitePlanDO } : { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };


  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);


  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  function getDataGap(params) {

    const field = params.field

    const startDate = field === "SubmissionGap" ? dayjs(params?.row?.SubmissionPlanDate) : field === "ApprovalGap" ? dayjs(params?.row?.ApprovalPlanDate) : dayjs(params?.row?.IssuePlanDate);
    const finishDate = field === "SubmissionGap" ? params?.row?.SubmissionActualDate === undefined ? null : dayjs(params?.row?.SubmissionActualDate) :
      field === "ApprovalGap" ? params?.row?.ApprovalActualDate === undefined ? null : dayjs(params?.row?.ApprovalActualDate) : params?.row?.IssueActualDate === undefined ? null : dayjs(params?.row?.IssueActualDate);
    const gap = finishDate && finishDate.diff(startDate, 'day', true)

    if (gap) {
      return gap
    } else {
      return null;
    }
  }

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },

    { field: 'record_no', headerName: "레코드 번호", width: 100, editable: false },
    {
      field: 'DrawingDiscPD', headerName: "공종", width: 80, editable: false,
      type: 'singleSelect', valueOptions: ["설비", "건축", "전기", "제어"]
    },
    {
      field: 'DPsubPD', headerName: "상세공종", width: 80, editable: false,
      type: 'singleSelect', valueOptions: ["배관", "건축", "랙", "전기", "제어", "설비제어", "전기제어", "HVAC"]
    },
    { field: 'DPAreaBPK', headerName: "Area", width: 100, editable: false },
    { field: 'DPFloor', headerName: "층", width: 100, editable: false, },
    { field: 'DrawingNo', headerName: "도면(문서) No", width: 180, editable: false },
    { field: 'DrawingName', headerName: '도면명', width: 350, editable: false, },
    { field: 'DPRevNo', headerName: "리비전", width: 100, editable: true },
    { field: 'VendorsDPK', headerName: "협력업체", width: 180, editable: false },

    { field: 'ReceiptPlanDate', headerName: "계획일", type: 'date', width: 100, editable: false },
    { field: 'ReceiptActualDate', headerName: "실적일", type: 'date', width: 100, editable: true },

    { field: 'CompletionPlanDate', headerName: "계획일", type: 'date', width: 100, editable: false },
    { field: 'CompletionActualDate', headerName: "실적일", type: 'date', width: 100, editable: true },


    { field: 'IssuePlanDate', headerName: "계획일", type: 'date', width: 100, editable: false },
    { field: 'IssueActualDate', headerName: "실적일", type: 'date', width: 100, editable: true },
    {
      field: 'IssueGap', headerName: "GAP", type: 'number', width: 60, editable: false,
      renderCell: (params) => {
        const planDate = dayjs(dayjs(params.row.IssuePlanDate).format("YYYY-MM-DD"));
        const actualDate = params.row.IssueActualDate ? dayjs(dayjs(params.row.IssueActualDate).format("YYYY-MM-DD") ): null;

        if (actualDate) {
          const gap = actualDate.diff(planDate, 'day');
          return <div>{gap}</div>;
        }

        return null;
      }
    },

    { field: 'SubmissionPlanDate', headerName: "계획일", type: 'date', width: 100, editable: false },
    { field: 'SubmissionActualDate', headerName: "실적일", type: 'date', width: 100, editable: true },
    {
      field: 'SubmissionGap', headerName: "GAP", type: 'number', width: 60, editable: false,
      renderCell: (params) => {
        const planDate =  dayjs(dayjs(params.row.SubmissionPlanDate).format("YYYY-MM-DD"));
        const actualDate = params.row.SubmissionActualDate ? dayjs(dayjs(params.row.SubmissionActualDate).format("YYYY-MM-DD") ): null;

        if (actualDate) {
          const gap = actualDate.diff(planDate, 'day');
          return <div>{gap}</div>;
        }

        return null;
      }
    },

    { field: 'ApprovalPlanDate', headerName: "계획일", type: 'date', width: 100, editable: false },
    { field: 'ApprovalActualDate', headerName: "실적일", type: 'date', width: 100, editable: true },
    {
      field: 'ApprovalGap', headerName: "GAP", type: 'number', width: 60, editable: false,
      renderCell: (params) => {
        const planDate = dayjs(dayjs(params.row.ApprovalPlanDate).format("YYYY-MM-DD"));
        const actualDate = params.row.ApprovalActualDate ? dayjs(dayjs(params.row.ApprovalActualDate).format("YYYY-MM-DD") ): null;

        if (actualDate) {
          const gap = actualDate.diff(planDate, 'day');
          return <div>{gap}</div>;
        }

        return null;
      }
    },

  ]


  const columnGroupingModel = [
    {
      groupId: '사전검토',
      children: [
        {
          groupId: '접수(시공사→설계사)',
          children: [{ field: 'ReceiptPlanDate' }, { field: 'ReceiptActualDate' }],
        },
        {
          groupId: '완료(설계사→시공사)',
          children: [{ field: 'CompletionPlanDate' }, { field: 'CompletionActualDate' }],
        },
      ],
    },
    {
      groupId: '도면 ISSUE',
      children: [{ field: 'IssuePlanDate' }, { field: 'IssueActualDate' }, { field: 'IssueGap' }],

    },
    {
      groupId: 'CPMS',
      children: [
        {
          groupId: '상신',
          children: [{ field: 'SubmissionPlanDate' }, { field: 'SubmissionActualDate' }, { field: 'SubmissionGap' }],
        },
        {
          groupId: '승인',
          children: [{ field: 'ApprovalPlanDate' }, { field: 'ApprovalActualDate' }, { field: 'ApprovalGap' }],
        },
      ],
    },

  ]


  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');


    function generateHeaders(data) {
      const topHeaders = [];
      const middleHeaders = [];
      const bottomHeaders = [];

      // Assuming all rows have the same keys, use the first row to determine headers
      const keys = Object.keys(data[0] || {});

      keys.forEach(key => {
        const parts = key.split('//');

        // Check the number of parts and assign accordingly
        if (parts.length === 1) {
          // No '//' in key
          topHeaders.push("");
          middleHeaders.push("");
          bottomHeaders.push(parts[0]);
        } else if (parts.length === 2) {
          // One '//' in key
          topHeaders.push(parts[0]);
          middleHeaders.push("");
          bottomHeaders.push(parts[1]);
        } else if (parts.length === 3) {
          // Two '//' in key
          topHeaders.push(parts[0]);
          middleHeaders.push(parts[1]);
          bottomHeaders.push(parts[2]);
        }
      });

      return { topHeaders, middleHeaders, bottomHeaders };
    }
    const initialData = rows.map((v) => ({
      "레코드 번호": v.record_no,
      "공종": v.DrawingDiscPD,
      "상세공종": v.DPsubPD,
      "Area": v.DPAreaBPK,
      "층": v.DPFloor,
      "도면(문서) No": v.DrawingNo,
      "도면명": v.DrawingName,
      "리비전": v.DPRevNo,
      "협력업체": v.VendorsDPK,
      "사전검토//접수(시공사→설계사)//계획일": v.ReceiptPlanDate ? dayjs(v.ReceiptPlanDate).format('YYYY-MM-DD') : "",
      "사전검토//접수(시공사→설계사)//실적일": v.ReceiptActualDate ? dayjs(v.ReceiptActualDate).format('YYYY-MM-DD') : "",
      "사전검토//완료(설계사→시공사)//계획일": v.CompletionPlanDate ? dayjs(v.CompletionPlanDate).format('YYYY-MM-DD') : "",
      "사전검토//완료(설계사→시공사)//실적일": v.CompletionActualDate ? dayjs(v.CompletionActualDate).format('YYYY-MM-DD') : "",
      "도면 ISSUE//계획일": v.IssuePlanDate ? dayjs(v.IssuePlanDate).format('YYYY-MM-DD') : "",
      "도면 ISSUE//실적일": v.IssueActualDate ? dayjs(v.IssueActualDate).format('YYYY-MM-DD') : "",
      "CPMS//상신//계획일": v.SubmissionPlanDate ? dayjs(v.SubmissionPlanDate).format('YYYY-MM-DD') : "",
      "CPMS//상신//실적일": v.SubmissionActualDate ? dayjs(v.SubmissionActualDate).format('YYYY-MM-DD') : "",
      "CPMS//승인//계획일": v.ApprovalPlanDate ? dayjs(v.ApprovalPlanDate).format('YYYY-MM-DD') : "",
      "CPMS//승인//실적일": v.ApprovalActualDate ? dayjs(v.ApprovalActualDate).format('YYYY-MM-DD') : "",
    }));
    const { topHeaders, middleHeaders, bottomHeaders } = generateHeaders(initialData);
    // 헤더 행 추가

    worksheet.addRow(topHeaders);
    worksheet.addRow(middleHeaders);
    worksheet.addRow(bottomHeaders);

    const headerRow = worksheet.getRow(1);
    const headerRow2 = worksheet.getRow(2);
    const headerRow3 = worksheet.getRow(3);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' } // 회색 배경
      };
      cell.font = {
        bold: true,
      };
    });
    headerRow2.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' } // 회색 배경
      };
      cell.font = {
        bold: true,
      };
    });
    headerRow3.eachCell((cell) => {
      let bgColor = 'FFD3D3D3'; // 기본 회색
      if (cell.value === "레코드 번호" || cell.value.includes("실적일")) {
        bgColor = 'FFFAA45A'; // 주황색
      }

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: bgColor }
      };
      cell.font = {
        bold: true,
      };
    });


    worksheet.columns.forEach(column => {
      let maxWidth = 10; // 최소 너비
      column.eachCell({ includeEmpty: true }, cell => {
        let cellLength = cell.value ? cell.value.toString().length : 0;
        if (cellLength > maxWidth) {
          maxWidth = cellLength;
        }
      });
      column.width = maxWidth + 2;
    });

    mergeHeaderCells(topHeaders, 1);
    mergeHeaderCells(middleHeaders, 2);

    // 셀 병합을 위한 함수
    function mergeHeaderCells(headers, rowNumber) {
      let startColumn = 1;
      for (let i = 1; i <= headers.length; i++) {
        // 셀의 값이 비어있지 않은 경우에만 병합 검사
        if (headers[i - 1] !== "") {
          if (i === headers.length || headers[i] !== headers[i - 1]) {
            if (i - startColumn >= 1) {
              worksheet.mergeCells(rowNumber, startColumn, rowNumber, i);
            }
            startColumn = i + 1;
          }
        } else {
          // 셀의 값이 비어있는 경우, 다음 셀로 시작 열을 업데이트
          startColumn = i + 1;
        }
      }
    }


    // 데이터 행 추가
    initialData.forEach(dataRow => {
      const row = [];
      Object.keys(dataRow).forEach(key => {
        row.push(dataRow[key]);
      });
      worksheet.addRow(row);
    });

    // 파일 저장
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `승인도_실적입력_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);
  };

  const onAdd = (event) => {

    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = (ev) => {
      if (!ev?.target?.result) {
        return;
      }

      const wb = read(ev.target.result, { type: 'binary', cellText: false, cellDates: true });
      const sheets = wb.SheetNames;

      const allData = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1 , dateNF: 'yyyy-mm-dd' });


      const firstRow = [...allData[0], "CPMS"];
      const secondRow = allData[1];
      const thirdRow = allData[2];

      const fillEmptyWithPrevious = (arr) => {
        for (let i = 1; i < arr.length; i++) {
          if (arr[i] === null || arr[i] === "" || arr[i] === undefined) {
            arr[i] = arr[i - 1];
          }
        }
      };

      // 첫 번째와 두 번째 배열에서 비어있는 요소를 채움
      fillEmptyWithPrevious(firstRow);
      fillEmptyWithPrevious(secondRow);





      // 세 배열을 합침
      const combinedHeader = [...firstRow.map((cell, index) => {
        return `${firstRow[index]}//${secondRow[index]}//${thirdRow[index]}`;
      }).map(v => v.replace("////", ""))
        .map(v => v.replace("도면 ISSUE//완료(설계사→시공사)", "도면 ISSUE")),
        "CPMS//승인//계획일", "CPMS//승인//실적일"
      ]



      const originColumns = [
        "record_no",
        "DrawingDiscPD",
        "DPsubPD",
        "DPAreaBPK",
        "DPFloor",
        "DrawingNo",
        "DrawingName",

        "DPRevNo",
        "VendorsDPK",
        "ReceiptPlanDate",
        "ReceiptActualDate",

        "CompletionPlanDate",
        "CompletionActualDate",

        "IssuePlanDate",
        "IssueActualDate",

        "SubmissionPlanDate",
        "SubmissionActualDate",

        "ApprovalPlanDate",
        "ApprovalActualDate",

      ]


      const staticFields = [
        { displayName: "레코드 번호", key: "record_no" },
        { displayName: "공종", key: "DrawingDiscPD" },

        { displayName: "상세공종", key: "DPsubPD" },
        { displayName: "Area", key: "DPAreaBPK" },
        { displayName: "층", key: "DPFloor" },
        { displayName: "도면(문서) No", key: "DrawingNo" },
        { displayName: "도면명", key: "DrawingName" },

        { displayName: "리비전", key: "DPRevNo" },
        { displayName: "협력업체", key: "VendorsDPK" },
        { displayName: "사전검토//접수(시공사→설계사)//계획일", key: "ReceiptPlanDate" },
        { displayName: "사전검토//접수(시공사→설계사)//실적일", key: "ReceiptActualDate" },

        { displayName: "사전검토//완료(설계사→시공사)//계획일", key: "CompletionPlanDate" },
        { displayName: "사전검토//완료(설계사→시공사)//실적일", key: "CompletionActualDate" },

        { displayName: "도면 ISSUE//계획일", key: "IssuePlanDate" },
        { displayName: "도면 ISSUE//실적일", key: "IssueActualDate" },
        { displayName: "CPMS//상신//계획일", key: "SubmissionPlanDate" },
        { displayName: "CPMS//상신//실적일", key: "SubmissionActualDate" },

        { displayName: "CPMS//승인//계획일", key: "ApprovalPlanDate" },
        { displayName: "CPMS//승인//실적일", key: "ApprovalActualDate" },


      ];

      const headerKeyMapping = combinedHeader.reduce((acc, headerName, index) => {
        const staticField = staticFields.find(field => field.displayName === headerName);
        if (staticField) {
          acc[headerName] = staticField.key;
        } else {
          // For headers with '//', use the headerName itself as key
          acc[headerName] = headerName;
        }
        return acc;
      }, {});

      const headerValues = Object.values(headerKeyMapping);



      if (sheets.length) {
        if (headerValues.every(function (value, index) { return value === originColumns[index] })) {

          const dataRows = allData.slice(3);

          const excelrows = dataRows.map(row => {
            return combinedHeader.reduce((newRow, header, index) => {
              newRow[header] = row[index];
              return newRow;
            }, {});
          })
            .map(row =>
              staticFields.reduce((obj, col) => {
                if (["ReceiptPlanDate", "ReceiptActualDate", "CompletionPlanDate", "CompletionActualDate", "IssuePlanDate", "IssueActualDate", "SubmissionPlanDate", "SubmissionActualDate", "ApprovalPlanDate", "ApprovalActualDate"].includes(col.key) && row[col.displayName]) {
                  // Excel의 날짜를 JavaScript Date 객체로 변환
                  const dateValue = new Date(row[col.displayName]);
                  dateValue.setHours(dateValue.getHours() + 9); // UTC+9 (한국 시간)로 조정
                  obj[col.key] = isNaN(dateValue.getTime()) ? null : dateValue;
                } else {
                  obj[col.key] = row[col.displayName];
                }
                return obj;
              }, {})
            );

          const errorMessages = [];

          const allowRecord = rows.map((v => v.record_no));

          // 날짜 필드 유효성 검사를 위한 함수
          const isValidDate = (dateString) => {
            const date = new Date(dateString);
            return !isNaN(date.getTime());
          };


          // Check each row for valid values
          for (let i = 0; i < excelrows.length; i++) {
            const row = excelrows[i];
            let errorsForRow = [];

            if (!allowRecord.includes(row.record_no)) {
              errorsForRow.push("'레코드 번호'가 유효하지 않습니다.");
            }

            // 날짜 필드에 대한 유효성 검사
            const dateFields = ["ReceiptActualDate", "CompletionActualDate", "SubmissionActualDate", "ApprovalActualDate", "IssueActualDate"];

            for (let j = 0; j < dateFields.length; j++) {
              if (row[dateFields[j]] && !isValidDate(row[dateFields[j]])) {
                errorsForRow.push(`'${dateFields[j]}' 날짜가 유효하지 않습니다.`);

              }

              // if(j > 0 &&!areDatesValid(row[dateFields[j-1]] , row[dateFields[j]] )){
              //   errorsForRow.push(`'${dateFields[j]}' 날짜 순서가 올바르지 않습니다.`);
              // }
            }

            if (errorsForRow.length > 0) {
              errorMessages.push({ row: i, errors: errorsForRow });
            }

          }
          if (errorMessages.length > 0) {
            const worksheet = utils.aoa_to_sheet([[...firstRow, ""], [...secondRow, ""], [...thirdRow, "error"]]);

            // Find and merge cells with the same value in the top header
            let currentMerge = null;
            firstRow.forEach((header, index) => {
              if (header !== "" && (index === 0 || firstRow[index - 1] !== header)) {
                currentMerge = { s: { r: 0, c: index }, e: { r: 0, c: index } };
              }
              if (header !== "" && (index === firstRow.length - 1 || firstRow[index + 1] !== header)) {
                if (currentMerge) {
                  currentMerge.e.c = index;
                  if (!worksheet["!merges"]) worksheet["!merges"] = [];
                  worksheet["!merges"].push(currentMerge);
                }
              }
            });


            // Find and merge cells with the same value in the middle header
            let currentMergeMiddle = null;
            secondRow.forEach((header, index) => {
              if (header !== "" && (index === 0 || secondRow[index - 1] !== header)) {
                currentMergeMiddle = { s: { r: 1, c: index }, e: { r: 1, c: index } }; // Start of new merge range in row 1
              }
              if (header !== "" && (index === secondRow.length - 1 || secondRow[index + 1] !== header)) {
                if (currentMergeMiddle) {
                  currentMergeMiddle.e.c = index; // End of merge range
                  if (!worksheet["!merges"]) worksheet["!merges"] = [];
                  worksheet["!merges"].push(currentMergeMiddle);
                }
              }
            });



            excelrows.forEach((row, index) => {
              const errorForRow = errorMessages.find(e => e.row === index);
              if (errorForRow) {
                row['error'] = errorForRow.errors.join('; ');
              }
            });


            // Append your data rows
            const dataRows = excelrows.map(row => [...headerValues, "error"].map(fieldName => row[fieldName] || ""));
            utils.sheet_add_json(worksheet, dataRows, { origin: -1, skipHeader: true });

            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, "Sheet1");
            writeFile(workbook, `임포트 오류_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);


            // 오류 알림
            Swal.fire({
              icon: 'error',
              title: '오류가 발견되었습니다. 다운로드된 엑셀 파일을 확인하세요.',
              confirmButtonText: '확인'
            });


          } else {

            // excelrows.forEach(row => {
            //   ["ReceiptActualDate", "CompletionActualDate", "IssueActualDate", "SubmissionActualDate", "ApprovalActualDate"].forEach(dateField => {
            //     if (row[dateField]) {
            //       const date = new Date(row[dateField]);
            //       date.setHours(date.getHours() + 9); // UTC+9 (한국 시간)로 조정
            //       // row[dateField] = new Date(dayjs(date).format("YYYY-MM-DD"));
            //     }
            //   });
            // });


            // rows 배열에서 record_no를 키로 사용하는 객체 맵 생성
            const rowsMap = rows.reduce((map, row) => {
              map[row.record_no] = row;
              return map;
            }, {});

            // excelrows에서 각 객체를 순회하면서 "Plan"을 포함하는 키의 값을 rowsMap에서 대체
            const updatedRows = excelrows.map((row) => {
              const newRow = { ...row, id: row.record_no }; // 기본적으로 id를 추가

              // "Plan"을 포함하는 키를 찾아 그 값으로 대체
              Object.keys(newRow).forEach(key => {
                if (key.includes("Plan") && rowsMap[row.record_no] && rowsMap[row.record_no][key] !== undefined) {
                  newRow[key] = rowsMap[row.record_no][key];
                }
              });

              return newRow;
            });

            // 최종 결과를 setRows에 전달
            setRows(updatedRows);

            Swal.fire({
              icon: 'success',
              title: '엑셀을 테이블로 로드했습니다. 데이터에 문제가 없으면 실적 전송 버튼을 클릭하세요',
              timer: 5000
            });
          }
        }

        else {
          Swal.fire({
            icon: 'error',
            title: '임포트한 엑셀 헤더를 확인하세요. 다운로드에 있는 헤더를 사용해야합니다.',
            timer: 5000
          });
        }
      }

    };
    reader.readAsArrayBuffer(file);

  };


  const handlerequest = () => {
    // 날짜 형식 변환 함수
    const formatDate = (date) => {
      if (!date) return null; // null 또는 undefined 처리
      return dayjs(date).format("MM-DD-YYYY");
    };

    // 주어진 객체에서 필요한 필드만 선택하여 새 객체 생성
    const selectFields = (obj, fields) => {
      return fields.reduce((acc, field) => {
        acc[field] = obj[field];
        return acc;
      }, {});
    };

    const keysToCompare = ["ReceiptActualDate", "CompletionActualDate", "SubmissionActualDate", "ApprovalActualDate", "IssueActualDate"];
    const requiredFields = ["record_no", ...keysToCompare]; // 필요한 필드 목록

    const updateData = rows
      .filter(v => v.record_no !== undefined) // record_no가 정의된 요소만 필터링
      .map(v => ({
        ...v,
        ReceiptActualDate: formatDate(v.ReceiptActualDate),
        CompletionActualDate: formatDate(v.CompletionActualDate),
        SubmissionActualDate: formatDate(v.SubmissionActualDate),
        ApprovalActualDate: formatDate(v.ApprovalActualDate),
        IssueActualDate: formatDate(v.IssueActualDate),
      }))
      .filter(dataStateElement => {
        const tableDataElement = tabeldata
          .map(v => ({
            ...v,
            ReceiptActualDate: formatDate(v.ReceiptActualDate),
            CompletionActualDate: formatDate(v.CompletionActualDate),
            SubmissionActualDate: formatDate(v.SubmissionActualDate),
            ApprovalActualDate: formatDate(v.ApprovalActualDate),
            IssueActualDate: formatDate(v.IssueActualDate),
          }))
          .find(e => e.record_no === dataStateElement.record_no);

        if (!tableDataElement) return false;

        return keysToCompare.some(key => dataStateElement[key] !== tableDataElement[key]);
      })
      .map(v => selectFields(v, requiredFields)); // 필요한 필드만 선택


    Swal.fire({
      title: '정말 승인도 DP 실적을 유니파이어로 보내겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '네',
      cancelButtonText: '아니오'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post("/submitDP", { type: "승인도", project: projectCode.projectnumber, updateData: updateData, createData: [] })
          .then((response) => {
            if (response.data.success) {
              Swal.fire({
                title: '성공적으로 제출되었습니다',
                icon: 'success',
                confirmButtonText: '확인'
              }).then(() => {
                navigate('/adrawinginput');
              });
            } else {
              Swal.fire({
                title: '에러 발생',
                icon: 'error',
                text: response.data.data,
                confirmButtonText: '확인'
              })
            }
          })
          .catch((err) => {
            Swal.fire({
              title: '문제가 발생했습니다',
              icon: 'error',
              text: err
            });
          });
      }
    });

  }

  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [{ id, isNew: true }, ...oldRows]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'DrawingName' },
      }));
    };

    return (
      <GridToolbarContainer>
        {/* <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button> */}
        <GridToolbarQuickFilter />
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', position: "absolute", right: 8 }}>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'flex-end' }}>
            <ButtonGroup size="small" color="success" variant="outlined" aria-label="outlined button group">
              <Button type='button' onClick={downloadExcel} startIcon={<DownloadIcon />}>다운로드</Button>
              <Button type='button' onClick={handleButtonClick} startIcon={<UploadIcon />}>임포트
                <input type="file" accept=".csv, .xls, .xlsx" hidden ref={fileInputRef}
                  onChange={onAdd}
                /></Button>
              <Button type='button' onClick={handlerequest} endIcon={<SendIcon />}>실적 전송</Button>
            </ButtonGroup>

          </div>
        </div>
      </GridToolbarContainer>
    );
  }




  return (
    <>
      <div style={{ marginBottom: 8 }}>

      </div>
      <Box
        sx={{
          height: "70vh",
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGridPro
          rows={rows}
          initialState={{ pinnedColumns: { left: ['actions'] } }}
          density="compact"
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          pagination
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={columnGroupingModel}
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </>
  );
};