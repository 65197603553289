import React, { useEffect, useState, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons, GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';
import { randomId } from '@mui/x-data-grid-generator';
import { read, utils, writeFile } from 'xlsx';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import _ from 'lodash';
import axios from "../axios";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import ButtonGroup from '@mui/material/ButtonGroup';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

dayjs.extend(isSameOrBefore);

// Check if dates are in the correct order
const areDatesValid = (firstDate, secondDate) => {
  if (!firstDate || !secondDate) {
    // If either date is missing, validation fails
    return true;
  }
  // Both dates exist, check their order
  return dayjs(firstDate).isSameOrBefore(dayjs(secondDate));
};



export const MFormTableInput = ({ tabeldata, projectCode }) => {


  const [rows, setRows] = React.useState(tabeldata.map((v, index) => ({
    ...v,
    id: index + 1,
  })));

  useEffect(() => {
    setRows(tabeldata.map((v, index) => ({
      ...v,
      id: index + 1,
    })))
  }, [tabeldata])

  const [rowModesModel, setRowModesModel] = React.useState({});
  let navigate = useNavigate();

  ////////////////유효성
  const StyledBox = styled(Box)(({ theme }) => ({
    height: "70vh",
    width: '100%',
    '& .Mui-error': {
      backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
      color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
    },
  }));


  ///////////////////



  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const handleRowEditStop = (params, event) => {
    if (params?.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };


  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };


  /////////validataion update////
  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);



  const processRowUpdate = (newRow) => {
    // Define the required fields and their error messages

    const date = new Date(newRow.BIMActualDate);
    const date2 = new Date(newRow.DrawingReleaseActualDate);


    if (isNaN(date.getTime()) && isNaN(date2.getTime())) {
      throw new Error(`레코드 저장 중 에러 발생: 실적 날짜 중 하나는 필수적으로 입력되어있어야합니다.`);
    }

    if (
      !areDatesValid(newRow['BIMActualDate'], newRow['DrawingReleaseActualDate'])

    ) {
      throw new Error("실적 날짜에 오류가 있습니다. 실적날짜는 전 단계의 날짜보다 같거나 커야합니다.");
    }

    // If all checks pass, update the row
    const updatedRow = newRow.WStart === null ? { ...newRow, isNew: false, WStart: newRow.MDSitePlanDO } : { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };


  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);


  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  function getDataGap(params) {

    const field = params.field

    const startDate = field === "SubmissionGap" ? dayjs(params?.row?.SubmissionPlanDate) : field === "ApprovalGap" ? dayjs(params?.row?.ApprovalPlanDate) : dayjs(params?.row?.IssuePlanDate);
    const finishDate = field === "SubmissionGap" ? params?.row?.SubmissionActualDate === undefined ? null : dayjs(params?.row?.SubmissionActualDate) :
      field === "ApprovalGap" ? params?.row?.ApprovalActualDate === undefined ? null : dayjs(params?.row?.ApprovalActualDate) : params?.row?.IssueActualDate === undefined ? null : dayjs(params?.row?.IssueActualDate);
    const gap = finishDate && finishDate.diff(startDate, 'day', true)

    if (gap) {
      return gap
    } else {
      return null;
    }
  }


  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },

    { field: 'record_no', headerName: "레코드 번호", width: 100, editable: false },
    {
      field: 'DrawingDiscPD', headerName: "공종", width: 80, editable: false,
      type: 'singleSelect', valueOptions: ["설비", "건축", "전기", "제어"]
    },
    { field: 'DPsubPD', headerName: "상세공종", width: 80, editable: false },
    { field: 'DPAreaBPK', headerName: "Area", width: 100, editable: false },
    { field: 'DPFloor', headerName: "층", width: 100, editable: false, },
    {
      field: 'DrawingNo', headerName: "도면(문서) No",

      width: 180, editable: false
    },
    {
      field: 'DrawingClassification', headerName: "도면구분",

      width: 80, editable: false, type: 'singleSelect', valueOptions: ["승인도", "제작도"]
    },
    { field: 'DrawingSystem', headerName: '성상', width: 90, editable: false },
    { field: 'DrawingMaterial', headerName: '재질', width: 90, editable: false },
    { field: 'DrawingSize', headerName: '사이즈', width: 90, editable: false },

    { field: 'DPRevNo', headerName: "리비전", width: 100, editable: true },
    // {field:'MDModuleNoDPK' , headerName:"모듈넘버",width:100, editable:false},
    // {field:'WStart' , headerName:"기준 날짜", type: 'date',width:100, editable:false},
    { field: 'VendorsDPK', headerName: "협력업체", width: 180, editable: false },

    { field: 'ReceiptScheduledDate', headerName: "선출도 접수 예정일", type: 'date', width: 100, editable: false },

    { field: 'BIMPlanDate', headerName: "계획일", type: 'date', width: 100, editable: false },
    { field: 'BIMActualDate', headerName: "실적일", type: 'date', width: 100, editable: true },
    {
      field: 'BIMGap', headerName: "GAP", type: 'number', width: 80, editable: false,
      renderCell: (params) => {
        const planDate = dayjs(dayjs(params.row.BIMPlanDate).format("YYYY-MM-DD"));
        const actualDate = params.row.BIMActualDate ? dayjs(dayjs(params.row.BIMActualDate).format("YYYY-MM-DD") ): null;

        if (actualDate) {
          const gap = actualDate.diff(planDate, 'day');
          return <div>{gap}</div>;
        }

        return null;
      }
    },

    { field: 'DrawingReleasePlanDate', headerName: "계획일", type: 'date', width: 100, editable: false },
    { field: 'DrawingReleaseActualDate', headerName: "실적일", type: 'date', width: 100, editable: true },
    {
      field: 'DrawingReleaseGap', headerName: "GAP", type: 'number', width: 80, editable: false,
      renderCell: (params) => {
        const planDate = dayjs(dayjs(params.row.DrawingReleasePlanDate).format("YYYY-MM-DD"));
        const actualDate = params.row.DrawingReleaseActualDate ? dayjs(dayjs(params.row.DrawingReleaseActualDate).format("YYYY-MM-DD") ): null;

        if (actualDate) {
          const gap = actualDate.diff(planDate, 'day');
          return <div>{gap}</div>;
        }

        return null;
      }
    },

  ]

  const columnGroupingModel = [
    {
      groupId: '선출도',
      children: [{ field: 'ReceiptScheduledDate' }],
    },
    {
      groupId: '도면(BIM) 송부',
      children: [{ field: 'BIMPlanDate' }, { field: 'BIMActualDate' }, { field: 'BIMGap' }],
    },
    {
      groupId: '제작도 출도',
      children: [{ field: 'DrawingReleasePlanDate' }, { field: 'DrawingReleaseActualDate' }, { field: 'DrawingReleaseGap' }],

    },
  ]

  const excelColumns = [
    { key: "record_no", displayName: "레코드 번호" },
    { key: "DrawingDiscPD", displayName: "공종" },
    { key: "DPsubPD", displayName: "상세공종" },
    { key: "DPMType", displayName: "구분" },
    { key: "DPAreaBPK", displayName: "Area" },
    { key: "DPFloor", displayName: "층" },
    { key: "DrawingNo", displayName: "도면(문서) No" },
    { key: "DrawingClassification", displayName: "도면구분" },

    { key: "DrawingSystem", displayName: "성상" },
    { key: "DrawingMaterial", displayName: "재질" },
    { key: "DrawingSize", displayName: "사이즈" },
    { key: "DPRevNo", displayName: "리비전" },
    { key: "VendorsDPK", displayName: "협력업체" },
    { key: "ReceiptScheduledDate", displayName: "선출도 접수 예정일" },

    { key: "BIMPlanDate", displayName: "도면(BIM) 송부 계획" },
    { key: "BIMActualDate", displayName: "도면(BIM) 송부 실적" },
    { key: "DrawingReleasePlanDate", displayName: "제작도 출도 계획" },
    { key: "DrawingReleaseActualDate", displayName: "제작도 출도 실적" },
  ]

  const downloadExcel = async () => {
    // 새 워크북 생성
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // 열 정의 (스타일 없음)
    const columns = excelColumns.map(col => ({
      header: col.require ? `${col.displayName}*` : col.displayName,
      key: col.key,
      width: col.displayName.length, // 초기 열 너비 설정
    }));
    worksheet.columns = columns;

    // 데이터 행 추가

    rows.forEach(row => {
      const newRow = {};
      Object.keys(row).forEach(key => {
        if (["BIMPlanDate", "BIMActualDate", "DrawingReleasePlanDate", "DrawingReleaseActualDate", "ReceiptScheduledDate"].includes(key) && row[key]) {
          newRow[key] = dayjs(row[key]).format('YYYY-MM-DD'); // dayjs를 사용하여 날짜 포맷 변경
        } else {
          newRow[key] = row[key];
        }
      });
      worksheet.addRow(newRow);
    });


    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      let bgColor = 'FFD3D3D3'; // 기본 회색
      if (cell.value === "레코드 번호" || cell.value.includes("실적")) {
        bgColor = 'FFFAA45A'; // 주황색
      }

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: bgColor }
      };
      cell.font = {
        bold: true,
      };
    });

    // 열 너비 

    // 열 너비 조정
    worksheet.columns.forEach(column => {
      let maxWidth = 10; // 최소 너비
      column.eachCell({ includeEmpty: true }, cell => {
        let cellLength = cell.value ? cell.value.toString().length : 0;
        if (cellLength > maxWidth) {
          maxWidth = cellLength;
        }
      });
      column.width = maxWidth + 2;
    });

    // 파일 생성 및 저장
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `제작도_실적입력_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);
  };


  const onAdd = (event) => {

    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = (ev) => {
      if (!ev?.target?.result) {
        return;
      }

      const wb = read(ev.target.result, { type: 'binary', cellText: false, cellDates: true });
      const sheets = wb.SheetNames;

      const header = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1 })[0]

      const excelColumnsValues = excelColumns.map(v => v.displayName);

      const planDateFields = [
        "BIMPlanDate",
        "DrawingReleasePlanDate",
        "ReceiptScheduledDate",
      ];


      if (sheets.length) {
        if (header.length === excelColumnsValues.length &&
          header.every(function (value, index) { return value === excelColumnsValues[index] })) {

          const excelrows = utils.sheet_to_json(wb.Sheets[sheets[0]], { raw: false, dateNF: 'yyyy-mm-dd' })
            .map(row =>
              excelColumns.reduce((obj, col) => {
                if (["BIMPlanDate", "BIMActualDate", "DrawingReleasePlanDate", "DrawingReleaseActualDate", "ReceiptScheduledDate"].includes(col.key) && row[col.displayName]) {
                  // Excel의 날짜를 JavaScript Date 객체로 변환
                  const dateValue = new Date(row[col.displayName]);
                  // dateValue.setHours(dateValue.getHours() + 9); // UTC+9 (한국 시간)로 조정

                  obj[col.key] = isNaN(dateValue.getTime()) ? null : dateValue;
                } else {
                  obj[col.key] = row[col.displayName];
                }
                return obj;
              }, {})
            );

          const errorMessages = [];

          const allowRecord = rows.map((v => v.record_no));

          // 날짜 필드 유효성 검사를 위한 함수
          const isValidDate = (date) => {
            return date instanceof Date && !isNaN(date.getTime());
          };

          // Check each row for valid values
          for (let i = 0; i < excelrows.length; i++) {
            const row = excelrows[i];
            let errorsForRow = [];


            if (!allowRecord.includes(row.record_no)) {
              errorsForRow.push("'레코드 번호'가 유효하지 않습니다.");
            }

            // 날짜 필드에 대한 유효성 검사
            const dateFields = ["BIMActualDate", "DrawingReleaseActualDate"];
            for (let j = 0; j < dateFields.length; j++) {
              if (row[dateFields[j]] && !isValidDate(row[dateFields[j]])) {
                errorsForRow.push(`'${dateFields[j]}' 날짜가 유효하지 않습니다.`);

              }

              if (j > 0 && !areDatesValid(row[dateFields[j - 1]], row[dateFields[j]])) {
                errorsForRow.push(`'${dateFields[j]}' 날짜 순서가 올바르지 않습니다.`);
              }
            }

            if (errorsForRow.length > 0) {
              errorMessages.push({ row: i, errors: errorsForRow });
            }


          }

          if (errorMessages.length > 0) {
            const transformedExcelrows = excelrows.map(row =>
              excelColumns.reduce((newRow, column) => {
                newRow[column.displayName] = row[column.key];
                return newRow;
              }, {})
            );

            const excelrowswithError = transformedExcelrows.map((v, index) => ({
              ...v,
              error: (errorMessages.find(vf => vf.row === index) || { errors: [] }).errors.join(";")
            }));


            const updatedWorkbook = utils.book_new();
            const updatedSheet = utils.json_to_sheet(excelrowswithError);

            // 'error' 열 추가
            errorMessages.forEach(error => {
              const cellRef = utils.encode_cell({ c: updatedSheet['!ref'].length, r: error.row });
              updatedSheet[cellRef] = { v: error.errors.join('; ') };
            });
            utils.book_append_sheet(updatedWorkbook, updatedSheet, 'Sheet1');
            writeFile(updatedWorkbook, `임포트 오류_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);

            Swal.fire({
              icon: 'error',
              title: '오류가 발견되었습니다. 다운로드된 엑셀 파일을 확인하세요.',
              confirmButtonText: '확인'
            });
          } else {
            // rows 배열에서 record_no를 키로 사용하는 객체 맵 생성
            const rowsMap = rows.reduce((map, row) => {
              map[row.record_no] = row;
              return map;
            }, {});

            // excelrows에서 각 객체를 순회하면서 "Plan"을 포함하는 키의 값을 rowsMap에서 대체
            const updatedRows = excelrows.map((row) => {
              const newRow = { ...row, id: row.record_no }; // 기본적으로 id를 추가

              // "Plan"을 포함하는 키를 찾아 그 값으로 대체
              Object.keys(newRow).forEach(key => {
                if ((key.includes("Plan")|| key === "ReceiptScheduledDate") && rowsMap[row.record_no] && rowsMap[row.record_no][key] !== undefined) {
                  newRow[key] = rowsMap[row.record_no][key];
                }
              });

              return newRow;
            });

            // 최종 결과를 setRows에 전달
            setRows(updatedRows);

            Swal.fire({
              icon: 'success',
              title: '엑셀을 테이블로 로드했습니다. 데이터에 문제가 없으면 실적 전송 버튼을 클릭하세요',
              timer: 5000
            });

          }

        }

        else {
          Swal.fire({
            icon: 'error',
            title: '임포트한 엑셀 헤더를 확인하세요. 다운로드에 있는 헤더를 사용해야합니다.',
            timer: 5000
          });
        }
      }

    };
    reader.readAsArrayBuffer(file);

  };

  const handlerequest = () => {
    // 날짜 형식 변환 함수
    const formatDate = (date) => {
      if (!date) return null; // null 또는 undefined 처리
      return dayjs(date).format("MM-DD-YYYY");
    };

    // 주어진 객체에서 필요한 필드만 선택하여 새 객체 생성
    const selectFields = (obj, fields) => {
      return fields.reduce((acc, field) => {
        acc[field] = obj[field];
        return acc;
      }, {});
    };

    const keysToCompare = ["DrawingReleaseActualDate", "BIMActualDate"];
    const requiredFields = ["record_no", ...keysToCompare]; // 필요한 필드 목록

    const updateData = rows
      .filter(v => v.record_no !== undefined) // record_no가 정의된 요소만 필터링
      .map(v => ({
        ...v,
        DrawingReleaseActualDate: formatDate(v.DrawingReleaseActualDate),
        BIMActualDate: formatDate(v.BIMActualDate),
      }))
      .filter(dataStateElement => {
        const tableDataElement = tabeldata
          .map(v => ({
            ...v,
            DrawingReleaseActualDate: formatDate(v.DrawingReleaseActualDate),
            BIMActualDate: formatDate(v.BIMActualDate),
          }))
          .find(e => e.record_no === dataStateElement.record_no);

        if (!tableDataElement) return false;

        return keysToCompare.some(key => dataStateElement[key] !== tableDataElement[key]);
      })
      .map(v => selectFields(v, requiredFields)); // 필요한 필드만 선택

    Swal.fire({
      title: '정말 제작도 DP 실적을 유니파이어로 보내겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '네',
      cancelButtonText: '아니오'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post("/submitDP", { type: "제작도", project: projectCode.projectnumber, updateData: updateData, createData: [] })
          .then(response => {
            if (response.data.success) {
              Swal.fire({
                title: '성공적으로 제출되었습니다',
                icon: 'success',
                confirmButtonText: '확인'
              }).then(() => {
                navigate('/mdrawinginput');
              });
            } else {
              throw new Error(response.data.message);
            }
          })
          .catch(err => {
            Swal.fire({
              title: '에러 발생',
              icon: 'error',
              text: err.message || 'An unknown error occurred',
              confirmButtonText: '확인'
            });
          });
      }
    });

  }

  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [{ id, isNew: true }, ...oldRows]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'DrawingName' },
      }));
    };

    return (
      <GridToolbarContainer>
        {/* <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button> */}
        <GridToolbarQuickFilter />
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', position: "absolute", right: 8 }}>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'flex-end' }}>
            <ButtonGroup size="small" color="success" variant="outlined" aria-label="outlined button group">
              <Button type='button' onClick={downloadExcel} startIcon={<DownloadIcon />}>다운로드</Button>
              <Button type='button' onClick={handleButtonClick} startIcon={<UploadIcon />}>임포트
                <input type="file" accept=".csv, .xls, .xlsx" hidden ref={fileInputRef}
                  onChange={onAdd}
                /></Button>
              <Button type='button' onClick={handlerequest} endIcon={<SendIcon />}>실적 전송</Button>
            </ButtonGroup>

          </div>
        </div>
      </GridToolbarContainer>
    );
  }


  return (
    <>
      <div style={{ marginBottom: 8 }}>
      </div>
      <Box
        sx={{
          height: "70vh",
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGridPro
          rows={rows}
          initialState={{ pinnedColumns: { left: ['actions'] } }}
          density="compact"
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          pagination
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={columnGroupingModel}
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </>
  );
};