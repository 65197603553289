import React, { useEffect, useState, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons, useGridApiContext, GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';
import { randomId } from '@mui/x-data-grid-generator';
import { read, utils, writeFile } from 'xlsx';
import dayjs from 'dayjs';
import { ModuleTable } from './moduletable';
import SearchIcon from '@mui/icons-material/Search';
import Swal from 'sweetalert2';
import _ from 'lodash';
import axios from "../axios";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Circles } from 'react-loader-spinner';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';



export const MFormTable = ({ tabeldata, moduleData, areaList, vendorList, timeData, projectCode }) => {


  const [rows, setRows] = React.useState(tabeldata.map((v, index) => ({
    ...v,
    id: index + 1,
  })));

  useEffect(() => {
    setRows(tabeldata.map((v, index) => ({
      ...v,
      id: index + 1,
    })))
  }, [tabeldata])

  const [rowModesModel, setRowModesModel] = React.useState({});
  let navigate = useNavigate();


  ///////////////////

  const downloadExcelModule = () => {
    const worksheet = utils.json_to_sheet(
      moduleData.map((v) => ({
        "레코드 번호": v.record_no,
        "모듈넘버": v.MDModuleNumber,
        "모듈 위치": v.ModuleAreaSPD,
        "현장 입고 계획일": v.MDSitePlanDO,
      }))
      // .map((v)=>{ delete v.bItemID; delete v.inEdit; delete v.Description; return v; })
    );
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    writeFile(workbook, '모듈정보.xlsx');
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [{ id, isNew: true }, ...oldRows]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'DrawingNo' },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
        <GridToolbarQuickFilter />

        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', position: "absolute", right: 8 }}>
          <button className='btn btn-sm btn-light btn-color-muted btn-active-light-primary' type='button' onClick={downloadExcelModule}>
            <i className="ki-duotone ki-file-down fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>모듈 정보 다운로드
          </button>
          <button className='btn btn-sm btn-light btn-color-muted btn-active-light-primary' type='button' onClick={downloadExcel}>
            <i className="ki-duotone ki-file-down fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>다운로드
          </button>
          <button className='btn btn-sm btn-light btn-color-muted btn-active-light-primary' type='button' onClick={handleButtonClick}>
            <i className="ki-duotone ki-file-up fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>임포트
            <input type="file" accept=".csv, .xls, .xlsx" hidden ref={fileInputRef}
              onChange={onAdd}
            />
          </button>
          <button className='btn btn-sm btn-light btn-color-muted btn-active-light-danger' type='button'
            onClick={handlerequest}
          >
            <i className="ki-duotone ki-exit-right fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>계획 확정
          </button>
        </div>
      </GridToolbarContainer>
    );
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };


  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };


  /////////validataion update////
  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const processRowUpdate = (newRow) => {
    // Define the required fields and their error messages
    const requiredFields = [
      { field: 'DPFloor', message: "층은 필수값입니다." },
      { field: 'DrawingClassification', message: "도면 구분은 필수값입니다." },
      { field: 'DrawingNo', message: "도면 번호는 필수값입니다." },
      { field: 'DPMType', message: "구분은 필수값입니다." },
      { field: 'DPsubPD', message: "상세공종은 필수값입니다." },
      { field: 'DrawingDiscPD', message: "공종은 필수값입니다." },
      { field: 'DPAreaBPK', message: "Area는 필수값입니다." },
      { field: 'DPRevNo', message: "도면 리비전 번호는 필수값입니다." },
    ];


    const date = new Date(newRow.WStart);
    const date2 = new Date(newRow.MDSitePlanDO);


    if (isNaN(date.getTime()) && isNaN(date2.getTime())) {
      throw new Error(`레코드 저장 중 에러 발생: 기준 날짜나 모듈 입고일 중 하나는 필수적으로 입력되어있어야합니다.`);
    }

    // Check each required field
    for (const { field, message } of requiredFields) {
      // Check if the value is not present
      if (!newRow[field]) {
        throw new Error(`레코드 저장 중 에러 발생: ${message}`);
      }

      // Check if the value is not a string or if the string value is empty
      if (typeof newRow[field] !== 'string' || newRow[field].trim() === '') {
        throw new Error(`레코드 저장 중 에러 발생: ${message}`);
      }

    }


    // If all checks pass, update the row
    const updatedRow = newRow.WStart === null ? { ...newRow, isNew: false, WStart: newRow.MDSitePlanDO } : { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);


  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };



  const [moduleTabeData, setModuleTabeData] = React.useState(moduleData);
  const [currentData, setCurrentData] = React.useState(null);


  const handleModuleData = (data) => {
    if (data.filter(v => v.selected).length > 0) {
      const selectedData = _.minBy(data.filter(v => v.selected), "MDSitePlanDO");
      const moduleNumbers = selectedData.MDModuleNumber;
      const moduleDate = dayjs(selectedData.MDSitePlanDO).toDate();


      currentData.MDSitePlanDO = moduleDate;
      currentData.MDModuleNoDPK = moduleNumbers;

      const updatedDataState = rows.map(item => {
        if (item.id === currentData.id) {
          return { ...item, ...currentData };
        }
        return item;
      });

      console.log(updatedDataState)

      setRows(updatedDataState);

    }

    setOpen(false);

  };


  function AutocompleteCell(props) {
    const { id, value, field, valueOptions } = props;
    const apiRef = useGridApiContext();

    const handleChange = (event, newValue) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };


    return (
      <Autocomplete
        value={value}
        onChange={handleChange}
        options={vendorList}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="업체" />}
      />
    );
  }


  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },

    { field: 'record_no', headerName: "레코드 번호", width: 100, editable: false },
    {
      field: 'DrawingDiscPD', headerName: "공종*", width: 80, editable: true,
      type: 'singleSelect', valueOptions: ["설비"]
    },
    {
      field: 'DPsubPD', headerName: "상세공종*", width: 80, editable: true,
      type: 'singleSelect', valueOptions: ["배관", "HVAC"]
    },
    {
      field: 'DPMType', headerName: "구분*", width: 80, editable: true, type: 'singleSelect',
      valueOptions:
        ({ row }) => {
          if (!row) {
            return ["-", "모듈", "비모듈", "GAL'V", "GAL'V 외"];
          }
          return row.DPsubPD === 'HVAC'
            ? ["GAL'V", "GAL'V 외"]
            : ["-", "모듈", "비모듈"];
        },

    },
    { field: 'DPAreaBPK', headerName: "Area*", width: 100, editable: true, type: 'singleSelect', valueOptions: areaList },
    { field: 'DPFloor', headerName: "층*", width: 100, editable: true, },
    {
      field: 'DrawingNo', headerName: "도면(문서) No*",

      width: 180, editable: true
    },
    {
      field: 'DrawingClassification', headerName: "도면구분*",

      width: 80, editable: true, type: 'singleSelect', valueOptions: ["승인도", "제작도"]
    },
    { field: 'DrawingSystem', headerName: '성상', width: 90, editable: true },
    { field: 'DrawingMaterial', headerName: '재질', width: 90, editable: true },
    { field: 'DrawingSize', headerName: '사이즈', width: 90, editable: true },

    { field: 'DPRevNo', headerName: "리비전*", width: 100, editable: true },
    {
      field: 'module',
      type: 'actions',
      headerName: 'Module 조회',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SearchIcon />}
              label="Search"
              className="textPrimary"
              onClick={() => handleShowModal(id)}
              color="inherit"
            />,
          ];
        }

        return [];
      },
    },
    { field: 'MDModuleNoDPK', headerName: "모듈넘버", width: 100, editable: false },
    { field: 'MDSitePlanDO', headerName: "모듈 입고일", width: 100, type: 'date', editable: false },
    {
      field: 'WStart', headerName: "기준 날짜*", type: 'date', width: 100,
      renderCell: (params) => {
        const date = params.row.MDSitePlanDO;
        const inputDate = params.value;

        if (date) {
          return dayjs(date).format('YYYY-MM-DD');
        } else {
          return inputDate ? dayjs(inputDate).format('YYYY-MM-DD') : '';
        }
      },
      editable: true
    },

    { field: 'ApprovedDrawingNotxt', headerName: '승인도 도면 No', width: 90, editable: true },
    {
      field: 'VendorsDPK', headerName: "협력업체*", width: 180, editable: true, type: 'singleSelect',
      renderEditCell: AutocompleteCell,
    },
    {
      field: 'ReceiptScheduledDate',
      headerName: "선출도 계획일",
      type: 'date',
      width: 100,
      editable: true,
      valueGetter: (value, row) => {
        const key = projectCode.projectnumber + "제작도" + row.DPsubPD + row.DPMType;
        const date = currentData && currentData.MDSitePlanDO !== undefined && currentData.id === row.id
          ? currentData.MDSitePlanDO
          : row.WStart;
        const timeKey = "DrawingReceipt";
        const timegap = timeData && timeData.length > 0 && timeData.find(v => v.uniqtext === key)
          ? timeData.find(v => v.uniqtext === key)[timeKey]
          : 0;

        const userInputDate = row.ReceiptScheduledDate;

        if(userInputDate){
          return dayjs(userInputDate).toDate();
        } else if (date){
          return dayjs(date).add(timegap, 'day').toDate();
        } else {
          return null;
        }

      },
    },
    {
      field: 'BIMPlanDate', headerName: "도면(BIM) 송부 계획일", type: 'date', width: 100,
      valueGetter: (value, row) => {
        const key = projectCode.projectnumber + "제작도" + row.DPsubPD + row.DPMType;
        const date = currentData && currentData.MDSitePlanDO !== undefined && currentData.id === row.id
          ? currentData.MDSitePlanDO
          : row.WStart;
        const timeKey = "BIMSubmission";
        const timegap = timeData && timeData.length > 0 && timeData.find(v => v.uniqtext === key)
          ? timeData.find(v => v.uniqtext === key)[timeKey]
          : 0;

          const userInputDate = row.BIMPlanDate;

          if(userInputDate){
            return dayjs(userInputDate).toDate();
          } else if (date){
            return dayjs(date).add(timegap, 'day').toDate();
          } else {
            return null;
          }
      },
      editable: true
    },
    {
      field: 'DrawingReleasePlanDate', headerName: "제작도 출도 계획일", type: 'date', width: 100,
      valueGetter: (value, row) => {
        const key = projectCode.projectnumber + "제작도" + row.DPsubPD + row.DPMType;
        const date = currentData && currentData.MDSitePlanDO !== undefined && currentData.id === row.id
          ? currentData.MDSitePlanDO
          : row.WStart;
        const timeKey = "DrawingRelease";
        const timegap = timeData && timeData.length > 0 && timeData.find(v => v.uniqtext === key)
          ? timeData.find(v => v.uniqtext === key)[timeKey]
          : 0;
          const userInputDate = row.DrawingReleasePlanDate;

          if(userInputDate){
            return dayjs(userInputDate).toDate();
          } else if (date){
            return dayjs(date).add(timegap, 'day').toDate();
          } else {
            return null;
          }
      }, editable: true
    },
  ]

  const excelColumns = [
    { key: "DrawingDiscPD", displayName: "공종", require: true },
    { key: "DPsubPD", displayName: "상세공종", require: true },
    { key: "DPMType", displayName: "구분", require: true },
    { key: "DPAreaBPK", displayName: "Area", require: true },
    { key: "DPFloor", displayName: "층", require: true },
    { key: "DrawingClassification", displayName: "도면구분", require: true },
    { key: "DrawingNo", displayName: "도면(문서) No", require: true },
    { key: "DrawingSystem", displayName: "성상" },
    { key: "DrawingMaterial", displayName: "재질" },
    { key: "DrawingSize", displayName: "사이즈" },
    { key: "DPRevNo", displayName: "리비전", require: true },
    { key: "MDModuleNoDPK", displayName: "모듈넘버" },
    { key: "WStart", displayName: "기준 날짜", require: true },
    { key: "ApprovedDrawingNotxt", displayName: "승인도 도면 No" },
    { key: "VendorsDPK", displayName: "협력업체", require: true },

  ]


  const downloadExcel = async () => {
    // 새 워크북 생성
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // 열 정의 (스타일 없음)
    const columns = excelColumns.map(col => ({
      header: col.require ? `${col.displayName}*` : col.displayName,
      key: col.key,
      width: col.displayName.length + 2, // 초기 열 너비 설정
    }));
    worksheet.columns = columns;

    // 데이터 행 추가
    // rows.forEach(row => {
    //   // 날짜 필드를 서울 시간으로 변환
    //   if (row.WStart) {
    //     row.WStart = dayjs.tz(row.WStart, "Asia/Seoul").format("YYYY-MM-DD");
    //   }
    //   if (row.MDSitePlanDO) {
    //     row.MDSitePlanDO = dayjs.tz(row.MDSitePlanDO, "Asia/Seoul").format("YYYY-MM-DD");
    //   }
    //   worksheet.addRow(row);
    // });

    rows.forEach(row => {
      const newRow = {};
      Object.keys(row).forEach(key => {
        if (["WStart", "MDSitePlanDO"].includes(key) && row[key]) {
          newRow[key] = dayjs(row[key]).format('YYYY-MM-DD'); // dayjs를 사용하여 날짜 포맷 변경
        } else {
          newRow[key] = row[key];
        }
      });
      worksheet.addRow(newRow);
    });


    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell, colNumber) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' } // 회색 배경
      };
      cell.font = {
        bold: true,
        color: excelColumns[colNumber - 1].require ? { argb: 'FFFF0000' } : { argb: 'FF000000' },
      };
    });

    // 열 너비 

    // 열 너비 조정
    worksheet.columns.forEach(column => {
      let maxWidth = 10; // 최소 너비
      column.eachCell({ includeEmpty: true }, cell => {
        let cellLength = cell.value ? cell.value.toString().length : 0;
        if (cellLength > maxWidth) {
          maxWidth = cellLength;
        }
      });
      column.width = maxWidth + 2;
    });

    // A열에 드롭다운 리스트 추가
    let validationType = {
      type: 'list',
      formulae: ['"설비"'],
      showDropDown: true,
    };

    let validationDetailType = {
      type: 'list',
      formulae: ['"배관,HVAC"'],
      showDropDown: true,
    };

    let validationDocType = {
      type: 'list',
      formulae: ['"승인도,제작도"'],
      showDropDown: true,
    };

    const Gubun = ["-", "모듈", "비모듈", "GAL'V", "GAL'V 외"];
    const GubunString = Gubun.map(item => item).join(",");


    let validationGubunType = {
      type: 'list',
      formulae: [`"${GubunString}"`],
      showDropDown: true,
    };

    const AreaString = areaList.map(item => item).join(",");


    let validationAreaType = {
      type: 'list',
      formulae: [`"${AreaString}"`],
      showDropDown: true,
    };

    // A열의 1행부터 5000행까지 드롭다운 적용
    for (let i = 2; i <= 5000; i++) {
      worksheet.getCell(`A${i}`).dataValidation = validationType;
      worksheet.getCell(`B${i}`).dataValidation = validationDetailType;
      worksheet.getCell(`C${i}`).dataValidation = validationGubunType;
      worksheet.getCell(`D${i}`).dataValidation = validationAreaType;
      worksheet.getCell(`F${i}`).dataValidation = validationDocType;
    }
    // 파일 생성 및 저장
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `제작도_dp_계획작성_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);
  };



  const onAdd = (event) => {

    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = (ev) => {
      if (!ev?.target?.result) {
        return;
      }

      const wb = read(ev.target.result, { type: 'binary', cellText: false, cellDates: true });
      const sheets = wb.SheetNames;

      const header = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1 })[0]
        .map((cell) => (cell ? cell.replace(/\*/g, '') : cell));
      const excelColumnsValues = excelColumns.map(v => v.displayName)


      if (sheets.length) {
        if (header.length === excelColumnsValues.length &&
          header.every(function (value, index) { return value === excelColumnsValues[index] })) {

          const allData = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1, blankrows: false });

          const excelHeader = allData[0].map((cell) => (cell ? cell.replace(/\*/g, '') : cell));
          const dataRows = allData.slice(1);

          const excelrows = dataRows.map(row => {
            return excelHeader.reduce((newRow, header, index) => {
              newRow[header] = row[index];
              return newRow;
            }, {});
          })
            .map(row =>
              excelColumns.reduce((obj, col) => {
                if (["WStart", "MDSitePlanDO", "ReceiptScheduledDate", "BIMPlanDate", "DrawingReleasePlanDate"].includes(col.key) && row[col.displayName]) {
                  obj[col.key] = new Date(row[col.displayName]);
                } else {
                  obj[col.key] = row[col.displayName];
                }
                return obj;
              }, {})
            )

          const errorMessages = [];

          const allowedDrawingDiscPD = ["설비"];
          const allowedDPsubPD = ["배관", "HVAC"];
          const allowedDPsubPD2 = ["모듈", "비모듈", "-", "GAL'V", "GAL'V 외"];


          // Check each row for valid values
          for (let i = 0; i < excelrows.length; i++) {
            const row = excelrows[i];

            let errorsForRow = [];

            let invalidColumn = "";

            // Adjust requiredColumns based on DPsubPD value
            let requiredColumns;
            let convertedColumns;
            if (row.DPsubPD === "배관" && (row.MDModuleNoDPK !== "" && row.MDModuleNoDPK !== null && row.MDModuleNoDPK !== undefined)) {
              requiredColumns = ["DrawingDiscPD", "DPsubPD", "DPMType", "DPAreaBPK", "DPFloor", "DrawingClassification", "DrawingNo", "DPRevNo", "MDModuleNoDPK", "VendorsDPK"];
              convertedColumns = ["공종", "상세공종", "구분", "Area", "층", "도면구분", "도면(문서) No", "리비전", "모듈넘버", "협력업체"];
            } else if (row.DPsubPD === "배관" && (row.MDModuleNoDPK === "" || row.MDModuleNoDPK === null)) {
              requiredColumns = ["DrawingDiscPD", "DPsubPD", "DPMType", "DPAreaBPK", "DPFloor", "DrawingClassification", "DrawingNo", "DPRevNo", "WStart", "VendorsDPK"];
              convertedColumns = ["공종", "상세공종", "구분", "Area", "층", "도면구분", "도면(문서) No", "리비전", "기준 날짜", "협력업체"];
            }

            else if (row.DPsubPD === "HVAC" && (row.MDModuleNoDPK !== "" && row.MDModuleNoDPK !== null && row.MDModuleNoDPK !== undefined)) {
              requiredColumns = ["DrawingDiscPD", "DPsubPD", "DPMType", "DPAreaBPK", "DPFloor", "DrawingClassification", "DrawingNo", "DPRevNo", "MDModuleNoDPK", "VendorsDPK", "DrawingSystem", "DrawingMaterial", "DrawingSize", "ApprovedDrawingNotxt"];
              convertedColumns = ["공종", "상세공종", "구분", "Area", "층", "도면구분", "도면(문서) No", "리비전", "모듈넘버", "협력업체", "성상", "재질", "사이즈", "승인도 도면 No"];
            }
            else if (row.DPsubPD === "HVAC" && (row.MDModuleNoDPK === "" || row.MDModuleNoDPK === null)) {
              requiredColumns = ["DrawingDiscPD", "DPsubPD", "DPMType", "DPAreaBPK", "DPFloor", "DrawingClassification", "DrawingNo", "DPRevNo", "WStart", "VendorsDPK", "DrawingSystem", "DrawingMaterial", "DrawingSize", "ApprovedDrawingNotxt"];
              convertedColumns = ["공종", "상세공종", "구분", "Area", "층", "도면구분", "도면(문서) No", "리비전", "기준 날짜", "협력업체", "성상", "재질", "사이즈", "승인도 도면 No"];
            }
            else if (row.DPsubPD !== "HVAC" && row.DPsubPD !== "배관" && (row.MDModuleNoDPK === "" || row.MDModuleNoDPK === null)) {
              requiredColumns = ["DrawingDiscPD", "DPsubPD", "DPMType", "DPAreaBPK", "DPFloor", "DrawingClassification", "DrawingNo", "DPRevNo", "WStart", "VendorsDPK", "DrawingSystem", "DrawingMaterial", "DrawingSize", "ApprovedDrawingNotxt"];
              convertedColumns = ["공종", "상세공종", "구분", "Area", "층", "도면구분", "도면(문서) No", "리비전", "기준 날짜", "협력업체", "성상", "재질", "사이즈", "승인도 도면 No"];
            }
            else if (row.DPsubPD !== "HVAC" && row.DPsubPD !== "배관" && (row.MDModuleNoDPK !== "" || row.MDModuleNoDPK !== null)) {
              requiredColumns = ["DrawingDiscPD", "DPsubPD", "DPMType", "DPAreaBPK", "DPFloor", "DrawingClassification", "DrawingNo", "DPRevNo", "MDModuleNoDPK", "VendorsDPK", "DrawingSystem", "DrawingMaterial", "DrawingSize", "ApprovedDrawingNotxt"];
              convertedColumns = ["공종", "상세공종", "구분", "Area", "층", "도면구분", "도면(문서) No", "리비전", "모듈넘버", "협력업체", "성상", "재질", "사이즈", "승인도 도면 No"];
            } else {
              requiredColumns = ["DrawingDiscPD", "DPsubPD", "DPMType", "DPAreaBPK", "DPFloor", "DrawingClassification", "DrawingNo", "DPRevNo", "WStart", "VendorsDPK"];
              convertedColumns = ["공종", "상세공종", "구분", "Area", "층", "도면구분", "도면(문서) No", "리비전", "기준 날짜", "협력업체"];
            }

            requiredColumns.forEach((col, index) => {
              if (!row[col] && row[col] !== 0) {
                errorsForRow.push(`'${convertedColumns[index]}' 필드가 누락되었습니다.`);
              }
            });


            if (!allowedDrawingDiscPD.includes(row.DrawingDiscPD)) {
              invalidColumn = "공종";
            } else if (!allowedDPsubPD.includes(row.DPsubPD)) {
              invalidColumn = "상세공종";
            } else if (!allowedDPsubPD2.includes(row.DPMType)) {
              invalidColumn = "구분";
            } else if (!vendorList.includes(row.VendorsDPK)) {
              invalidColumn = "업체명";
            } else if (!areaList.includes(row.DPAreaBPK)) {
              invalidColumn = "Area";
            }

            if (invalidColumn) {
              errorsForRow.push(`${invalidColumn} 값을 확인하세요.`);
            }


            if (errorsForRow.length > 0) {
              errorMessages.push({ row: i, errors: errorsForRow });
            }

          }

          if (errorMessages.length > 0) {

            const transformedRows = excelrows.map(row => {
              const newRow = {};
              excelColumns.forEach(column => {
                newRow[column.displayName] = row[column.key] || ''; // column.key가 없는 경우 빈 문자열을 할당
              });
              return newRow;
            })

            transformedRows.forEach((row, index) => {
              const errorForRow = errorMessages.find(e => e.row === index);
              if (errorForRow) {
                row['error'] = errorForRow.errors.join('; ');
              }
            });

            const updatedWorkbook = utils.book_new();
            const updatedSheet = utils.json_to_sheet(transformedRows);

            utils.book_append_sheet(updatedWorkbook, updatedSheet, 'Sheet1');
            writeFile(updatedWorkbook, `임포트 오류_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);

            Swal.fire({
              icon: 'error',
              title: '오류가 발견되었습니다. 다운로드된 엑셀 파일을 확인하세요.',
              confirmButtonText: '확인'
            });

          } else {
            excelrows.forEach(row => {
              // row가 유효하고 MDModuleNoDPK 속성이 존재하며, 이 속성이 null이나 undefined가 아닌지 확인
              if (row && row.hasOwnProperty("MDModuleNoDPK") && row["MDModuleNoDPK"]) {
                const moduleNumbers = row["MDModuleNoDPK"].split(',');

                // 일치하는 MDModuleNumber 항목 필터링
                const matchedModules = moduleData.filter(md =>
                  moduleNumbers.includes(md.MDModuleNumber)
                );

                // 가장 작은 MDSitePlanDO를 가진 항목 찾기
                const earliestModule = _.minBy(matchedModules, 'MDSitePlanDO');

                // 필요한 경우 row 업데이트
                if (earliestModule) {
                  // Date 객체로 변환 (시간대 조정 포함)
                  const date = new Date(earliestModule.MDSitePlanDO);
                  date.setHours(date.getHours() + 9); // UTC+9 (한국 시간)로 조정

                  row["WStart"] = date;
                  row["MDModuleNoDPK"] = earliestModule.MDModuleNumber;
                  row["MDSitePlanDO"] = date;
                }
              }

              // 다른 날짜 필드들도 Date 객체로 변환
              ["WStart", "MDSitePlanDO", "ReceiptScheduledDate", "BIMPlanDate", "DrawingReleasePlanDate"].forEach(dateField => {
                if (row[dateField]) {
                  const date = new Date(row[dateField]);
                  date.setHours(date.getHours() + 9); // UTC+9 (한국 시간)로 조정
                  row[dateField] = date;
                }
              });
            });

            const add_row = _.differenceBy(excelrows.map((v) => ({ ...v, uniq: v.DrawingNo + v.DPRevNo })), rows.map((v) => ({ ...v, uniq: v.DrawingNo + v.DPRevNo })), 'uniq').map((v) => { delete v.record_no; return v; })
              .map((v) => ({ ...v, id: randomId() }))
            const exist = _.differenceBy(excelrows.map((v) => ({ ...v, uniq: v.DrawingNo + v.DPRevNo })), add_row.map((v) => ({ ...v, uniq: v.DrawingNo + v.DPRevNo })), 'uniq');

            const updatedData = exist.map((v) => {
              const recordNo = rows.find(item => item.DrawingNo + item.DPRevNo === v.uniq)?.record_no || '';
              return { ...v, record_no: recordNo };
            })
              .map((v) => ({ ...v, id: rows.find(vf => vf.record_no === v.record_no).id }))

            // 새로운 행 추가
            const newDataState = [...updatedData, ...add_row];
            setRows(newDataState);

            // setState2(!state)
            Swal.fire({
              icon: 'success',
              title: '엑셀을 테이블로 로드했습니다. 데이터에 문제가 없으면 계획 확정 버튼을 클릭하세요',
              confirmButtonText: '확인'
            });
          }
        }

        else {
          Swal.fire({
            icon: 'error',
            title: '임포트한 엑셀 헤더를 확인하세요. 다운로드에 있는 헤더를 사용해야합니다.',
            confirmButtonText: '확인'
          });
        }
      }

    };
    reader.readAsArrayBuffer(file);

  };


  const handlerequest = () => {
    const keysToCompare = ['VendorsDPK', 'DPRevNo', 'DPAreaBPK', 'DPFloor', 'DPsubPD', 'DPMType', 'DrawingDiscPD', "DrawingClassification", 'DrawingNo', 'MDModuleNoDPK', 'WStart', 'DPRevNo', "DrawingSystem", "DrawingMaterial", "DrawingSize", "ApprovedDrawingNotxt", 'ReceiptScheduledDate', 'BIMPlanDate', 'DrawingReleasePlanDate'];
    const values = ["공종", "상세공종", "구분", "Area", "층", "도면구분", "도면(문서) No", "리비전", "기준 날짜", "협력업체", "선출도 계획일", "도면(BIM) 송부 계획일", "제작도 출도 계획일"];
    const keys = ["DrawingDiscPD", "DPsubPD", "DPMType", "DPAreaBPK", "DPFloor", "DrawingClassification", "DrawingNo", "DPRevNo", "WStart", "VendorsDPK", "ReceiptScheduledDate", "BIMPlanDate", "DrawingReleasePlanDate"];
  
    const processedRows = rows.map(row => {
      const newRow = { ...row };
      const key = projectCode.projectnumber + "제작도" + row.DPsubPD + row.DPMType;
      const defaultDate = row.MDSitePlanDO || row.WStart;
  
      const calculateDate = (fieldName, timeKey) => {
        if (newRow[fieldName] === null || newRow[fieldName] === undefined) {
          const timegap = timeData && timeData.length > 0 && timeData.find(v => v.uniqtext === key)
            ? timeData.find(v => v.uniqtext === key)[timeKey]
            : 0;
          return defaultDate ? dayjs(defaultDate).add(timegap, 'day').toDate() : null;
        }
        return newRow[fieldName];
      };
  
      newRow.ReceiptScheduledDate = calculateDate('ReceiptScheduledDate', 'DrawingReceipt');
      newRow.BIMPlanDate = calculateDate('BIMPlanDate', 'BIMSubmission');
      newRow.DrawingReleasePlanDate = calculateDate('DrawingReleasePlanDate', 'DrawingRelease');
  
      return newRow;
    });
  
    for (let row of processedRows) {
      for (let i = 0; i < keys.length; i++) {
        if (row[keys[i]] === '' || row[keys[i]] === null) {
          Swal.fire({
            title: 'Error',
            text: `Missing or empty value for "${values[i]}" in one or more records.`,
            icon: 'error',
            confirmButtonText: 'Ok'
          });
          return; // Exit the function early
        }
      }
    }
  
    const updateData = processedRows
      .filter(v => v.record_no !== undefined && v.record_no !== "")
      .map((v) => ({ 
        ...v, 
        WStart: dayjs(v.WStart).format("MM-DD-YYYY"),
        ReceiptScheduledDate: v.ReceiptScheduledDate ? dayjs(v.ReceiptScheduledDate).format("MM-DD-YYYY") : null,
        BIMPlanDate: v.BIMPlanDate ? dayjs(v.BIMPlanDate).format("MM-DD-YYYY") : null,
        DrawingReleasePlanDate: v.DrawingReleasePlanDate ? dayjs(v.DrawingReleasePlanDate).format("MM-DD-YYYY") : null
      }))
      .filter(dataStateElement => {
        const tableDataElement = tabeldata.map((v) => ({ ...v, WStart: dayjs(v.WStart).format("MM-DD-YYYY") })).find(e => e.record_no === dataStateElement.record_no);
        if (!tableDataElement) return false;
  
        return keysToCompare.some(key => dataStateElement[key] !== tableDataElement[key]);
      })
      .map(v => ({
        ...v,
        DPLeadTimeDP: projectCode.projectnumber + "제작도" + v.DPsubPD + v.DPMType,
        uniqtext: projectCode.projectnumber + "제작도" + v.DPsubPD + v.DPMType,
      }));
  
    const createData = processedRows
      .filter(v => v.record_no === undefined || v.record_no === "")
      .map((v) => ({ 
        ...v, 
        WStart: dayjs(v.WStart).format("MM-DD-YYYY"),
        ReceiptScheduledDate: v.ReceiptScheduledDate ? dayjs(v.ReceiptScheduledDate).format("MM-DD-YYYY") : null,
        BIMPlanDate: v.BIMPlanDate ? dayjs(v.BIMPlanDate).format("MM-DD-YYYY") : null,
        DrawingReleasePlanDate: v.DrawingReleasePlanDate ? dayjs(v.DrawingReleasePlanDate).format("MM-DD-YYYY") : null,
        DPLeadTimeDP: projectCode.projectnumber + "제작도" + v.DPsubPD + v.DPMType 
      }));
  
    // Rest of the function remains the same...
    Swal.fire({
      title: '정말 제작도 DP 계획을 유니파이어로 보내겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '네',
      cancelButtonText: '아니오'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post("/submitDP", { type: "제작도", project: projectCode.projectnumber, updateData: updateData, createData: createData })
          .then(response => {
            if (response.data.success) {
              Swal.fire({
                title: '성공적으로 제출되었습니다',
                icon: 'success',
                confirmButtonText: '확인'
              }).then(() => {
                navigate('/mdrawing');
              });
            } else {
              throw new Error(response.data.message);
            }
          })
          .catch(err => {
            Swal.fire({
              title: '에러 발생',
              icon: 'error',
              text: err.message || 'An unknown error occurred',
              confirmButtonText: '확인'
            });
          });
      }
    });
  };


  const [open, setOpen] = React.useState(false);


  const handleShowModal = (id) => {
    setOpen(true);
    setCurrentData(rows.find(vf => vf.id === id));
    if (moduleTabeData.length > 0) {
      setModuleTabeData(moduleTabeData.map((v) => ({ ...v, selected: false })))
    }
  };


  const handleClose = () => {
    setOpen(false);
  };

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth={800}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {currentData && currentData["DrawingNo"] + "를 위한 모듈 조회"}
        </DialogTitle>
        <DialogContent>
          <ModuleTable moduletabledata={moduleData} onModuleDataHandle={handleModuleData} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          height: "70vh",
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGridPro
          rows={rows}
          initialState={{ pinnedColumns: { left: ['actions'] } }}
          density="compact"
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          pagination

        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </>
  );
};