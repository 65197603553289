import React, {  useState } from 'react';
import axios from '../common/axios';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


export default function ResetPW() {
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(false);
	// const history = useHistory();
	const navigate = useNavigate(); // useNavigate 사용

	function isValidEmail(email) {
		const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		return re.test(email);
	}

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsValid(isValidEmail(emailValue));
    };

	const sendEmail = async (e) => {
	
		try {
			const response = await axios.post(`/dpresetpw`, { email });
			if (response.data.success) {
				Swal.fire({
					title: '성공!',
					text: '비밀번호 재설정 이메일이 발송되었습니다.',
					icon: 'success',
					confirmButtonText: '확인'
				}).then((result) => {
					if (result.isConfirmed) {
						navigate("/dplogin");
					}
				});
			} else {
				Swal.fire({
					title: '오류',
					text: response.data.message,
					icon: 'error',
					confirmButtonText: '확인'
				});
			}
		} catch (err) {
			const errorMessage = err.response?.data?.error || 'Error during login';
			Swal.fire({
				title: '오류',
				text: errorMessage,
				icon: 'error',
				confirmButtonText: '확인'
			});
		}
	};

    return (
        <>
		<div style={{backgroundImage: 'url(/images/bg4.jpg)', backgroundSize: 'cover', 
         backgroundPosition: 'center'}}  data-bs-theme="light">
		<div className="d-flex flex-column flex-root" style={{  minHeight:"100vh"}}>
		<div className="d-flex flex-column flex-column-fluid flex-lg-row">
				<div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
					<div className="d-flex flex-center flex-lg-start flex-column">
						<a href="http://www.samsungshi.com/kor/default.aspx" className="mb-7">
							<img alt="Logo" src='/images/g1313.png'  style={{width:250}} />
						</a>
						<h2 className="text-white fw-normal m-0">삼성중공업 하이테크 부문 DP</h2>
					</div>
				</div>
				<div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
					<div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
						<div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
							<form className="form w-100" noValidate="novalidate">
								<div className="text-center mb-10">
									<h1 className="text-dark fw-bolder mb-3">비밀번호 재설정</h1>
									<div className="text-gray-500 fw-semibold fs-6">비밀번호 재설정을 위한 이메일을 입력하세요.</div>
								</div>
								<div className="fv-row mb-8">
									<input type="text" placeholder="이메일" name="email" autoComplete="off" className="form-control bg-transparent"   onChange={handleEmailChange}/>
								</div>
								<div className="d-flex flex-wrap justify-content-center pb-lg-0">
									<button type="button" className="btn btn-primary me-4" disabled={!isValid} onClick={sendEmail}>
										<span className="indicator-label">이메일 보내기</span>
										<span className="indicator-progress">잠시만 기다려주세요...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
									</button>
									<RouterLink  className="btn btn-light"to={{pathname: "/login"}} >취소</RouterLink>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		</div>
        </>
    );
}