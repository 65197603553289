import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from "../common/axios";
import useDocumentTitle from '../common/useDocumentTitle'

const Nav = () => {

	useDocumentTitle("삼성중공업 - DP 시스템")

	const [isMenuVisible3, setMenuVisible3] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [translateValue3, setTranslateValue3] = useState({ x: 682, y: 74 });

	const [isMenuVisible, setMenuVisible] = useState(false);
	const [translateValue, setTranslateValue1] = useState({ x: 576, y: 74 });
	const [userData, setUserData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await axios.post(`/dpcurrent_user`, {}, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setUserData(userResponse.data); // Set user data using Recoil
            } catch (userError) {
                console.error("Error fetching user data:", userError);
                // Handle error in fetching user data
            }
        }
        fetchData()
    }, [])

	const shi = userData.companyType === "삼성중공업"

	useEffect(() => {
		function handleResize() {
			setWindowWidth(window.innerWidth);
		}

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		const screenResolution = window.screen.width;
		const xValue1 = ((326 / 1920) * screenResolution * windowWidth) / screenResolution; // 첫 번째 메뉴에 대한 x 값
		const xValue3 = ((432 / 1920) * screenResolution * windowWidth) / screenResolution; // 두 번째 메뉴에 대한 x 값
		const yValue = 74;
		setTranslateValue1({ x: xValue1, y: yValue });
		setTranslateValue3({ x: xValue3, y: yValue });
	}, [windowWidth]);



	let leaveTimeout;
	let leaveTimeout3;

	const handleMouseEnter = () => {
		clearTimeout(leaveTimeout); // 이전의 딜레이를 취소합니다.
		setMenuVisible(true);
	};

	const handleMouseLeave = () => {
		leaveTimeout = setTimeout(() => {
			setMenuVisible(false);
		}, 200);
	};




	const handleMouseEnter3 = () => {
		clearTimeout(leaveTimeout3); // 이전의 딜레이를 취소합니다.
		setMenuVisible3(true);
	};

	const handleMouseLeave3 = () => {
		leaveTimeout3 = setTimeout(() => {
			setMenuVisible3(false);
		}, 200);
	};


	const [moblestatus, setmoblestatus] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => {
			setmoblestatus(window.innerWidth <= 768); // 모바일 뷰의 너비 임계값을 설정하세요
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);



	return (

		<>
			<div className={isMenuVisible ? "menu-item show menu-dropdown" : "menu-item"} onClick={moblestatus ? () => setMenuVisible(!isMenuVisible) : undefined}
				onMouseEnter={!moblestatus ? handleMouseEnter : undefined}
				onMouseLeave={!moblestatus ? handleMouseLeave : undefined}>
				<span className="menu-link">
					<span className="menu-title">승인도 관리</span>
					<span className="menu-arrow d-lg-none"></span>
				</span>


				<div className=
					{`menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 ${moblestatus ? 'px-lg-2 py-lg-4 w-lg-200px' : 'px-lg-2 py-lg-4 w-lg-200px'} ${isMenuVisible ? 'show' : ""}`}
					style={moblestatus ? {} : isMenuVisible ? { zIndex: 107, position: "fixed", inset: "0px auto auto 0px", margin: 0, transform: `translate(${translateValue.x}px,${translateValue.y}px)`, display: "block", backgroundColor: "#ffffff" } : {}}>
					<div className="menu-item" style={{ display: shi ? "block" : "none" }}>
						<RouterLink to={{ pathname: "/adrawing" }} className="menu-link">
							<span className="menu-title">승인도 계획 관리</span>
						</RouterLink>
					</div>
					<div className="menu-item" style={{ display: shi ? "block" : "block" }}>
						<RouterLink to={{ pathname: "/adrawinginput" }} className="menu-link">
							<span className="menu-title">승인도 실적 입력</span>
						</RouterLink>
					</div>
					<div className="menu-item" style={{ display: shi ? "block" : "none" }}>
						<RouterLink to={{ pathname: "/adrawinglist" }} className="menu-link">
							<span className="menu-title">승인도 조회</span>
						</RouterLink>
					</div>

				</div>
			</div>

			<div className={isMenuVisible3 ? "menu-item show menu-dropdown" : "menu-item"} onClick={moblestatus ? () => setMenuVisible3(!isMenuVisible3) : undefined}
				onMouseEnter={!moblestatus ? handleMouseEnter3 : undefined}
				onMouseLeave={!moblestatus ? handleMouseLeave3 : undefined}>
				<span className="menu-link">
					<span className="menu-title">제작도 관리</span>
					<span className="menu-arrow d-lg-none"></span>
				</span>


				<div className=
					{`menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 ${moblestatus ? 'px-lg-2 py-lg-4 w-lg-200px' : 'px-lg-2 py-lg-4 w-lg-200px'} ${isMenuVisible3 ? 'show' : ""}`}
					style={moblestatus ? {} : isMenuVisible3 ? { zIndex: 107, position: "fixed", inset: "0px auto auto 0px", margin: 0, transform: `translate(${translateValue3.x}px,${translateValue3.y}px)`, display: "block", backgroundColor: "#ffffff" } : {}}>
					<div className="menu-item" style={{ display: shi ? "block" : "none" }}>
						<RouterLink to={{ pathname: "/mdrawing" }} className="menu-link">
							<span className="menu-title">제작도 계획 관리</span>
						</RouterLink>
					</div>
					<div className="menu-item" style={{ display: shi ? "block" : "block" }}>
						<RouterLink to={{ pathname: "/mdrawinginput" }} className="menu-link">
							<span className="menu-title">제작도 실적 입력</span>
						</RouterLink>
					</div>
					<div className="menu-item" style={{ display: shi ? "block" : "none" }}>

						<RouterLink to={{ pathname: "/mdrawinglist" }} className="menu-link">
							<span className="menu-title">제작도 조회</span>
						</RouterLink>
					</div>

				</div>
			</div>

		</>
	)
};

export default Nav;