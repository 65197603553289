import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from '../common/axios';
import { Circles } from 'react-loader-spinner';

const checkSession = async () => {
  try {
    const response = await axios.post(
      `/dpis_logged_in`,
      {},
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return { isValidSession: response.data.isLoggedIn };
  } catch (error) {
    console.error("Error verifying session:", error);
    return { isValidSession: false };
  }
};

const ProtectedRoute = ({ children }) => {
  const [isValidSession, setIsValidSession] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const validity = await checkSession();
      setIsValidSession(validity.isValidSession);
      setIsLoading(false);

      if (!validity.isValidSession) {
        navigate('/dplogin', { state: { from: location }, replace: true });
      }
    };
    fetchData();
  }, [navigate, location]);

  if (isLoading) {
    return <div className="loadingContainer">
      <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
    </div>;
  }

  return isValidSession ? children : null;
};

export default ProtectedRoute;
