import React, { useState, useEffect } from 'react';
import axios from '../common/axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

export default function SignUp() {

	const navigate = useNavigate(); // useNavigate 사용

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [email, setEmail] = useState('');
	const [company, setCompany] = useState('');
	const [disciplines, setDisciplines] = useState([]);
	const [passwordValid, setPasswordValid] = useState([false, false, false, false]);
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [openTermsDialog, setOpenTermsDialog] = useState(false);
	const [showCompanyInput, setShowCompanyInput] = useState(false);
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [strength, setStrength] = useState(0);


	function getPasswordStrength(password) {
		let score = 0;
		const consecutivePattern = /(.)\1\1+/; // 3번 이상 연속되는 문자

		if (password.length >= 10) score++;
		if (/[^a-zA-Z0-9]/.test(password)) score++;
		if (/[0-9]/.test(password)) score++;
		if (/[a-z]/.test(password)) score++;
		if (/[A-Z]/.test(password)) score++;
		if (consecutivePattern.test(password)) score = 0; // 연속된 문자가 있다면 점수를 0으로 초기화

		return score;
	}

	useEffect(() => {
		setStrength(getPasswordStrength(password));
	}, [password]);


	const handleEmailChange = (e) => {
		const emailInput = e.target.value;
		setEmail(emailInput);
		setIsEmailValid(validateEmail(emailInput) || emailInput === '');
	};


	const validateEmail = (email) => {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		return emailRegex.test(email);
	};


	useEffect(() => {
		setShowCompanyInput(email.length > 0 && !email.endsWith('@samsung.com'));
	}, [email]);


	const handleOpenTermsDialog = () => setOpenTermsDialog(true);
	const handleCloseTermsDialog = () => setOpenTermsDialog(false);

	const handleDisciplineChange = (event) => {
		setDisciplines(event.target.value);
	};

	const handlePasswordChange = (e) => {
		const value = e.target.value;
		setPassword(value);
		const validity = [
			value.length >= 8,
			/[A-Z]/.test(value),
			/[a-z]/.test(value),
			/[!@#$%^&*]/.test(value),
		];
		setPasswordValid(validity);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		// API call logic here
		try {
			const response = await axios.post('/dpsignup', {
				username,
				password,
				email,
				company,
				disciplines,
			});
	
			if (response.data.success) {
				Swal.fire('성공', '회원 가입이 완료되었습니다. 가입 승인 메일을 받으신 후에 로그인이 가능합니다.', 'success');
				navigate('/dplogin'); // Redirect on success
			} else {
				// Handle backend validation errors
				Swal.fire('Error', response.data.message || '회원 가입 실패', 'error');
			}
		} catch (error) {
			// Handle network or other unexpected errors
			Swal.fire('Error', error.response?.data?.message || '회원 가입에 문제가 발생했습니다.', 'error');
		}
	};
	

	const disciplineOptions = ['설비', '건축', '전기', '제어'];


	// State to manage if the form is valid to submit
	const [isFormValid, setIsFormValid] = useState(false);

	// Check if all fields are valid and if passwords match
	useEffect(() => {
		const formIsValid =
			username &&
			password &&
			confirmPassword &&
			email &&
			isEmailValid &&
			(!showCompanyInput || (showCompanyInput && company)) &&
			disciplines.length > 0 &&
			password === confirmPassword &&
			passwordValid.every(Boolean) &&
			acceptedTerms;

		setIsFormValid(formIsValid);
	}, [username, password, confirmPassword, email, company, disciplines, passwordValid, acceptedTerms, isEmailValid, showCompanyInput]);



	return (
		<>
			<Dialog open={openTermsDialog} onClose={handleCloseTermsDialog}>
				<DialogTitle>개인정보 수집 이용 동의</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<p>DP시스템 이용을 위하여 본인의 개인정보를 수집·이용하고자 하는 경우에는「개인정보 보호법」 제15조 제1항 제1호, 제22조 제3항에 따라 본인의 동의를 얻어야 합니다. 이에 본인은 귀 사가 아래의 내용과 같이 본인의 개인정보를 수집·이용 또는 제공하는 것에 동의합니다.</p>

						<h3>[수집·이용에 관한 사항]</h3>
						<ul>
							<li>
								<h4>1. 수집·이용 목적</h4>
								<p>DP 시스템 이용</p>
							</li>
							<li>
								<h4>2. 수집·이용할 항목</h4>
								<p>이메일, 회사명, 사업자등록번호</p>
							</li>
							<li>
								<h4>3. 보유·이용 기간</h4>
								<p>위 개인정보는 수집·이용에 관한 동의일로부터 동의 철회시까지 보유·이용할 수 있습니다. 단, 동의 철회일 후에는 위에 기재된 목적과 관련된 분쟁 해결, 민원처리, 법령상 의무이행을 위하여 필요한 범위 내에서만 보유·이용됩니다.</p>
							</li>
							<li>
								<h4>4. 동의를 거부할 권리 및 동의를 거부할 경우의 불이익</h4>
								<p>위 개인정보의 수집·이용에 대한 동의를 거부할 수 있으며, 동의 후에도 언제든지 철회 가능합니다. 다만, 동의하지 않은 수집·이용 목적과 관련된 광고심의시스템 이용 관련 서비스 및 안내 등의 편의는 제한될 수 있습니다.</p>
							</li>
						</ul>
					</DialogContentText>
					<div>
						<Checkbox
							checked={acceptedTerms}
							onChange={e => setAcceptedTerms(e.target.checked)}
						/>
						위 내용에 동의합니다.
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseTermsDialog}>닫기</Button>
				</DialogActions>
			</Dialog>

			<div style={{
				backgroundImage: 'url(/images/bg4.jpg)', backgroundSize: 'cover',
				backgroundPosition: 'center'
			}} data-bs-theme="light">
				<div className="d-flex flex-column flex-root" style={{ minHeight: "100vh" }}>
					<div className="d-flex flex-column flex-column-fluid flex-lg-row">
						<div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
							<div className="d-flex flex-center flex-lg-start flex-column">
								<a href="http://www.samsungshi.com/kor/default.aspx" className="mb-7">
									<img alt="Logo" src='/images/g1313.png' style={{ width: 250 }} />
								</a>
								<h2 className="text-white fw-normal m-0">삼성중공업 하이테크 부문 DP</h2>
							</div>
						</div>
						<div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
							<div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
								<div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
									<form className="form w-100" onSubmit={handleSubmit}>
										<div className="text-center mb-11">
											<h1 className="text-dark fw-bolder mb-3">회원 가입</h1>
											<div className="text-gray-500 fw-semibold fs-6">Samsung Heavy Industries Hightech</div>
										</div>
										<div className="fv-row mb-8">
											<input type="text" name="username" placeholder="로그인 아이디" onChange={e => setUsername(e.target.value)} autoComplete="off" className="form-control bg-transparent" />
										</div>
										<div className="fv-row mb-8">
											<div className="mb-1">
												<div className="position-relative mb-3">
													<input type="password" name="password" placeholder="비밀번호" value={password} onChange={handlePasswordChange} autoComplete="off" className="form-control bg-transparent" />
													<span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2">
														<i className="ki-duotone ki-eye-slash fs-2"></i>
														<i className="ki-duotone ki-eye fs-2 d-none"></i>
													</span>
												</div>
												{/* ... Password Strength Indicator ... */}
												<div className="d-flex align-items-center mb-3">
													<div className={`flex-grow-1 bg-secondary ${strength > 0 ? 'bg-active-success active' : 'bg-active-success'} rounded h-5px me-2`}></div>
													<div className={`flex-grow-1 bg-secondary ${strength > 1 ? 'bg-active-success active' : 'bg-active-success'} rounded h-5px me-2`}></div>
													<div className={`flex-grow-1 bg-secondary ${strength > 2 ? 'bg-active-success active' : 'bg-active-success'} rounded h-5px me-2`}></div>
													<div className={`flex-grow-1 bg-secondary ${strength > 3 ? 'bg-active-success active' : 'bg-active-success'} rounded h-5px me-2`}></div>
													<div className={`flex-grow-1 bg-secondary ${strength > 4 ? 'bg-active-success active' : 'bg-active-success'} rounded h-5px`}></div>
												</div>
												<div className="text-muted">10자 이상의 대소문자, 특수문자를 포함해주세요. 3자리 이상의 연속된 문자는 쓸 수 없습니다.</div>

											</div>
										</div>

										<div className="fv-row mb-8">
											<input
												placeholder="비밀번호 확인"
												name="confirm-password"
												type="password"
												value={confirmPassword}
												onChange={e => setConfirmPassword(e.target.value)}
												autoComplete="off"
												className="form-control bg-transparent"
											/>
											{password && confirmPassword && password !== confirmPassword && (
												<div style={{ color: 'red' }}>비밀번호가 일치하지 않습니다.</div>
											)}
										</div>
										<div className="fv-row mb-8">
											<input
												placeholder="이메일"
												name="email"
												type="email"
												value={email}
												onChange={handleEmailChange}
												autoComplete="off"
												className="form-control bg-transparent"
											/>
											{!isEmailValid && email.length > 0 && (
												<div style={{ color: 'red' }}>유효한 이메일 형식이 아닙니다.</div>
											)}
										</div>

										{showCompanyInput && (
											<div className="fv-row mb-8">
												<input
													placeholder="사업자등록번호(-를 함께 써주세요)"
													name="company"
													type="text"
													onChange={e => setCompany(e.target.value)}
													autoComplete="off"
													className="form-control bg-transparent"
												/>
											</div>
										)}


										<FormControl variant="filled" fullWidth style={{ marginBottom: '6px' }}>
											<InputLabel>공종</InputLabel>
											<Select
												multiple
												value={disciplines}
												// classes={{ root: classes.rootFirstSelect }}
												onChange={handleDisciplineChange}
												renderValue={(selected) => selected.join(', ')}
											>
												{disciplineOptions.map((name) => (
													<MenuItem key={name} value={name}>
														<Checkbox checked={disciplines.indexOf(name) > -1} />
														<ListItemText primary={name} />
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<div className="fv-row mb-8">
											<label className="form-check form-check-inline">
												<input
													className="form-check-input"
													type="checkbox"
													name="toc"
													value="1"
													checked={acceptedTerms}
													onChange={e => setAcceptedTerms(e.target.checked)}
												/>

												<span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">
													개인정보 수집에 <a href="#" className="ms-1 link-primary" onClick={handleOpenTermsDialog}>동의</a>합니다.</span>
											</label>
										</div>
										<div className="d-grid mb-10">
											<button type="submit" className="btn btn-primary" disabled={!isFormValid}>
												<span className="indicator-label">회원 가입 요청</span>
												<span className="indicator-progress">잠시만 기다려주세요...
													<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
											</button>
										</div>
										<div className="text-gray-500 text-center fw-semibold fs-6">이미 삼성중공업 하이테크의 DP 회원인가요?
											<RouterLink className="link-primary" to={{ pathname: "/dplogin" }} >로그인</RouterLink>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}