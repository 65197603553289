import React, {  useState } from 'react';
import axios from '../common/axios';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function SignIn() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
	const [userData, setUserData] = useState({});


	const navigate = useNavigate(); // useNavigate 사용

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const loginResponse = await axios.post(`/dplogin`, { username, password }, { withCredentials: true });
			
			if (loginResponse.data.success) {
				// Fetch user data after successful login

				Swal.fire({
					title: '로그인 성공!',
					text: loginResponse.data.message,
					icon: 'success',
					confirmButtonText: 'OK'
				}).then(() => {
					navigate("/home");
				});
			} else {
				Swal.fire({
					title: '문제가 있습니다!',
					text: loginResponse.data.message,
					icon: 'error',
					confirmButtonText: 'OK'
				});
			}
		} catch (err) {
			const errorMessage = err.response?.data?.error || '로그인 중 문제가 발생했습니다.';
		
			// 비밀번호 만료 오류에 대한 처리 추가
			if (err.response?.data?.passwordExpired) {
			  Swal.fire({
				title: '비밀번호 만료!',
				text: '비밀번호가 만료되었습니다. 비밀번호를 재설정해주세요.',
				icon: 'warning',
				confirmButtonText: '비밀번호 재설정'
			  }).then((result) => {
				if (result.isConfirmed) {
				  // 사용자가 확인 버튼을 클릭하면 비밀번호 재설정 페이지로 리다이렉션
				  navigate('/reset-password');
				}
			  });
			} else {
			  // 다른 종류의 오류에 대한 SweetAlert 표시
			  Swal.fire({
				title: '오류!',
				text: errorMessage,
				icon: 'error',
				confirmButtonText: 'OK'
			  });
			}
		  }
		};
		
	  
    return (
        <>
		<div style={{backgroundImage: 'url(/images/bg4.jpg)', backgroundSize: 'cover', 
         backgroundPosition: 'center'}}  data-bs-theme="light">
		<div className="d-flex flex-column flex-root" style={{  minHeight:"100vh"}}>
		<div className="d-flex flex-column flex-column-fluid flex-lg-row">
				<div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
					<div className="d-flex flex-center flex-lg-start flex-column">
						<a href="http://www.samsungshi.com/kor/default.aspx" className="mb-7">
							<img alt="Logo" src='/images/g1313.png'  style={{width:250}} />
						</a>
						<h2 className="text-white fw-normal m-0">삼성중공업 하이테크 부문 DP</h2>
					</div>
				</div>
				<div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
					<div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
						<div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
							<form className="form w-100" onSubmit={handleSubmit}>
								<div className="text-center mb-11">
									<h1 className="text-dark fw-bolder mb-3">로그인</h1>
									<div className="text-gray-500 fw-semibold fs-6">Samsung Heavy Industries Hightech</div>
								</div>

								<div className="row g-3 mb-9">
									<div className="col-md-6">
									
									</div>
									<div className="col-md-6">
									
									</div>
								</div>


								<div className="fv-row mb-8">
									<input type="text" name="username" placeholder="아이디" onChange={e => setUsername(e.target.value)} autoComplete="off" className="form-control bg-transparent" />
								</div>
								<div className="fv-row mb-3">
									<input type="password" placeholder="비밀번호" name="password" autoComplete="off" className="form-control bg-transparent" onChange={e => setPassword(e.target.value)}/>
								</div>
								<div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
									<div></div>
									<RouterLink  className="link-primary"to={{pathname: "/dpreset-password"}} >비밀번호를 잊으셨나요?</RouterLink>
								</div>
								<div className="d-grid mb-10">
									<button type="submit" className="btn btn-primary">
										<span className="indicator-label">로그인</span>
										<span className="indicator-progress">잠시만 기다려주세요...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
									</button>
								</div>
								<div className="text-gray-500 text-center fw-semibold fs-6">아직 삼성중공업 하이테크의 DP 회원이 아닌가요?
								<RouterLink  className="link-primary"to={{pathname: "/dpsignup"}} >회원가입</RouterLink>
								</div>
							</form>
						</div>
						{/* <div className="d-flex flex-stack px-lg-10">
							<div className="d-flex fw-semibold text-primary fs-base gap-5">
								<a href="#" target="_blank">Terms</a>
								<a href="#" target="_blank">Plans</a>
								<a href="#" target="_blank">Contact Us</a>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</div>
		</div>
        </>
    );
}