import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from "../common/axios";
import Layout from '../layout/Layout';
import { Circles } from 'react-loader-spinner';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { MFormTableList } from '../common/formtable/mdrawingtableList';
import dayjs from 'dayjs';
import useDocumentTitle from '../common/useDocumentTitle';
import { useLocation } from 'react-router-dom';


const DrawingMList = () => {
    const [tableData, setTableData] = useState([]);
    const [projectCodeList, setProjectCodeList] = useState([]);
    const [projectCode, setProjectCode] = useState({projectnumber:"",projectname:""});
	const [userData, setUserData] = useState({});
    const location = useLocation();
    // 프로젝트 코드 상태 초기화
    useEffect(() => {
        const projectnumber = localStorage.getItem('projectnumber');
        const projectname = localStorage.getItem('projectname');
        if (projectname && projectnumber) {
            setProjectCode({ projectnumber: projectnumber, projectname: projectname });
        }
    }, []);


    // 프로젝트 코드 변경 시 로컬 스토리지에 저장
    useEffect(() => {
        if (projectCode && projectCode.projectname !== "") {
            localStorage.setItem('projectnumber', (projectCode.projectnumber));
            localStorage.setItem('projectname', (projectCode.projectname));
        }
    }, [projectCode]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await axios.post(`/dpcurrent_user`, {}, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setUserData(userResponse.data); // Set user data using Recoil
            } catch (userError) {
                console.error("Error fetching user data:", userError);
                // Handle error in fetching user data
            }
        }
        fetchData()
    }, [location])


    const [loading, setLoading] = useState(true);
    useDocumentTitle("SHI DP 제작도 조회");

    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(`/getprojectlist`)
            if(res.data.success){
            const data = res.data.data
            setProjectCodeList(data)}
        }
        fetchData()
    }, [location])


    useEffect(() => {
        const fetchData = async () => {
            if(projectCode !== null && projectCode.projectnumber){
            const res = await axios.get(`/getdpMdata?path=${projectCode.projectnumber}`)
            if(res.data.success){
            const discipline = userData.DPDisciplineMS;

            const data = res.data.data.data.map((v)=>({
                ...v,
                WStart:v.WStart && new Date(v.WStart),
                ReceiptScheduledDate:v.ReceiptScheduledDate && new Date(dayjs(v.ReceiptScheduledDate).format("YYYY-MM-DD")),
                BIMPlanDate:v.BIMPlanDate && new Date(dayjs(v.BIMPlanDate).format("YYYY-MM-DD")),
                BIMActualDate:v.BIMActualDate && new Date(dayjs(v.BIMActualDate).format("YYYY-MM-DD")),
                DrawingReleasePlanDate:v.DrawingReleasePlanDate && new Date(dayjs(v.DrawingReleasePlanDate).format("YYYY-MM-DD")),
                DrawingReleaseActualDate:v.DrawingReleaseActualDate && new Date(dayjs(v.DrawingReleaseActualDate).format("YYYY-MM-DD")),

            }))
            // .filter(v=>discipline.includes(v.DrawingDiscPD))

            setTableData(data)}
            setLoading(false);

            }
        }
        fetchData()
    }, [projectCode, userData])


    return(<>
    <Layout>
    <div className='app-main flex-column flex-row-fluid '>
        <div className='d-flex flex-column flex-column-fluid'>
            <div className='app-toolbar  py-3 py-lg-6 '>
                <div className='app-container  container-xxl d-flex flex-stack '>
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                            {projectCode && projectCode.projectname}  
                        </h1>
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                <li className="breadcrumb-item text-muted">
                                    <span className="text-muted text-hover-primary">제작도</span>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                </li>
                                <li className="breadcrumb-item text-muted">
                                    조회                                        
                                </li>
                            </ul>
                    </div>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                    <Autocomplete
                            value={projectCode}
                            onChange={(event, newValue) => {
                            setProjectCode(newValue);
                            }}
                            options={projectCodeList}
                            getOptionLabel={(option) => option.projectname}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Project" />}
                        />
                    </div>
                </div>
            </div>
            <div className='app-content  flex-column-fluid 'style={{paddingTop:0}}>
                <div className='app-container container-xxl '>
                {loading && projectCode.projectnumber !== ""&&projectCode!==null?
                        <div className="loadingContainer">
                        <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
                        </div>
                        :
                        <MFormTableList tabeldata={tableData} projectCode={projectCode}/>
                    }
                </div>
            </div>
        </div>  
    </div>
    </Layout>
    </>);
};
export default DrawingMList;
