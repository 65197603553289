import React, { useEffect, useState,useRef,useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  DataGridPro,GridToolbar, GridToolbarFilterButton, GridToolbarColumnsButton,GridToolbarContainer
} from '@mui/x-data-grid-pro';
import { read, utils, writeFile } from 'xlsx';
import dayjs from 'dayjs';
import _ from 'lodash';
import DownloadIcon from '@mui/icons-material/Download';

export const MFormTableList = ({ tabeldata}) => {

    const rows = tabeldata.map((v,index)=>({
      ...v, 
      id:index+1,
    }))


    const columns = [

      {field:'record_no' , headerName:"레코드 번호" , width:100, editable:false},
      {field:'DrawingDiscPD' , headerName:"공종" , width:80, editable:false, },
      {field:'DPsubPD' , headerName:"상세공종" , width:80, editable:false},
      {field:'DPMType' , headerName:"구분" , width:80, editable:false,  },
      {field:'DPAreaBPK' , headerName:"Area" , width:100, editable:false},
      {field:'DPFloor' , headerName:"층" , width:100, editable:false, },
      {field:'DrawingNo' , headerName:"도면(문서) No" , 

      width:180, editable:false},
      // {
      //   field: 'DrawingName',
      //   headerName: '도면명',
      //   width: 350,
      //   editable: false,

      // },
      {field: 'DrawingSystem',headerName: '성상',width: 90,editable: false},
      {field: 'DrawingMaterial',headerName: '재질',width: 90,editable: false},
      {field: 'DrawingSize',headerName: '사이즈',width: 90,editable: false},

      {field:'DPRevNo' , headerName:"리비전" , width:100, editable:false},
      {field:'MDModuleNoDPK' , headerName:"모듈넘버",width:100, editable:false},
      {field:'WStart' , headerName:"기준 날짜", type: 'date',width:100, editable:false},
      {field: 'ApprovedDrawingNotxt',headerName: '승인도 도면 No',width: 90,editable: false},
      {field:'VendorsDPK' , headerName:"협력업체" , width:180, editable:false, },

      {field:'ReceiptScheduledDate' , headerName:"선출도 접수 예정일" , type: 'date', width:100, editable:false},

      {field:'BIMPlanDate' , headerName:"계획일" , type: 'date', width:100, editable:false},
      {field:'BIMActualDate' , headerName:"실적일" , type: 'date', width:100, editable:false},
      {field:'BIMGap' , headerName:"GAP" , type: 'number', width:80,editable:false},

      {field:'DrawingReleasePlanDate' , headerName:"계획일" , type: 'date', width:100, editable:false},
      {field:'DrawingReleaseActualDate' , headerName:"실적일" , type: 'date', width:100, editable:false},
      {field:'DrawingReleaseGap' , headerName:"GAP" , type: 'number', width:80,editable:false},

  ]



  const columnGroupingModel = [
    {
        groupId: '선출도',
        children: [{ field: 'ReceiptScheduledDate' }],
      },
      {
        groupId: '도면(BIM) 송부',
        children: [{ field: 'BIMPlanDate' }, { field: 'BIMActualDate' }, { field: 'BIMGap' }],
      },
      {
        groupId: '제작도 출도',
        children: [{ field: 'DrawingReleasePlanDate' }, { field: 'DrawingReleaseActualDate' }, { field: 'DrawingReleaseGap' }],

      },
]


    const downloadExcel = () => {
      const initialData = rows.map((v)=>({
          "레코드 번호":v.record_no,
          "공종":v.DrawingDiscPD,
          "상세공종":v.DPsubPD,
          "구분":v.DPMType,
          "Area":v.DPAreaBPK,
          "층":v.DPFloor,
          "도면(문서) No":v.DrawingNo,
          "도면명":v.DrawingName,
          "리비전":v.DPRevNo,
          "모듈넘버":v.MDModuleNoDPK,
          "기준 날짜":v.WStart,
          "협력업체":v.VendorsDPK,
          "선출도//접수 예정일":v.ReceiptScheduledDate,
          "도면(BIM) 송부//계획일":v.BIMPlanDate,
          "도면(BIM) 송부//실적일":v.BIMActualDate,
          "도면(BIM) 송부//GAP":v.BIMGap,

          "제작도 출도//계획일":v.DrawingReleasePlanDate,
          "제작도 출도//실적일":v.DrawingReleaseActualDate,
          "제작도 출도//GAP":v.DrawingReleaseGap,
  
        }));


    const topHeaders = ["", "", "", "", "", "", "", "", "", "", "", "", "선출도", "도면(BIM) 송부","도면(BIM) 송부","도면(BIM) 송부","제작도 출도","제작도 출도","제작도 출도"];
    const bottomHeaders = ["레코드 번호", "공종", "상세공종", "구분", "Area", "층", "도면(문서) No", "도면명", "리비전", "모듈넘버", "기준 날짜", "협력업체", "접수 예정일","계획일","실적일","GAP","계획일","실적일","GAP"];

    const worksheet = utils.aoa_to_sheet([topHeaders,bottomHeaders]);

    // Find and merge cells with the same value in the top header
    let currentMerge = null;
    topHeaders.forEach((header, index) => {
        if (header !== "" && (index === 0 || topHeaders[index - 1] !== header)) {
            currentMerge = { s: { r: 0, c: index }, e: { r: 0, c: index } };
        } 
        if (header !== "" && (index === topHeaders.length - 1 || topHeaders[index + 1] !== header)) {
            if (currentMerge) {
                currentMerge.e.c = index;
                if (!worksheet["!merges"]) worksheet["!merges"] = [];
                worksheet["!merges"].push(currentMerge);
            }
        }
    });


    const keysArray = Object.keys(initialData[0] || {});
    const dataRows = initialData.map(row => keysArray.map(fieldName => row[fieldName] || ""));
    utils.sheet_add_json(worksheet, dataRows, { origin: -1, skipHeader: true });

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `제작도_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <Box sx={{ flexGrow: 1 }} />
        <Button startIcon={<DownloadIcon />} size="small" onClick={downloadExcel}>
                    다운로드
                  </Button>
      </GridToolbarContainer>
    );
  }

    return (
    <>
    <Box sx={{ width: '100%' }}>
      {/* <Button startIcon={<DownloadIcon />} size="small" onClick={downloadExcel}>
        다운로드
      </Button> */}
        <Box
          sx={{
            height: "70vh",
            width: '100%',
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
            '& .cold': {
              backgroundColor: '#b9d5ff91',
              color: '#1a3e72',
            },
            '& .hot': {
              backgroundColor: '#ff943975',
              color: '#1a3e72',
            },
          }}
        >
          <DataGridPro
            rows={rows}
            density="compact"
            columns={columns}
            pagination
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroupingModel}
            slots={{
              toolbar: CustomToolbar,
            }}
            getCellClassName={(params) => {
              if ((params.field === 'DrawingReleaseGap' ||
              params.field === 'BIMGap' )&& params.value !== null&&params.value !== 0
              ) {
                return params.value >= 0 ? 'cold' : 'hot';
              }
            }}
          />

        </Box>
        </Box>
    </>
      );
};