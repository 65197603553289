import * as React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { DataGridPro,GridToolbarContainer } from '@mui/x-data-grid-pro';
import { Button } from '@mui/material';

export const ModuleTable = ({moduletabledata, onModuleDataHandle }) => {
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);


  const [rows, setRows] = React.useState(moduletabledata.map((v,index)=>({
    ...v, 
    id:index+1,
  })));

  const [selectedRows, setSelectedRows] = React.useState([]);

  React.useEffect(() => {
    const updatedSelectedRows = rows.map(row => ({
      ...row,
      selected: rowSelectionModel.includes(row.id)
    }));

    setSelectedRows(updatedSelectedRows);
  }, [rowSelectionModel, rows]);


 
  const columns = [
    {field: 'MDModuleNumber', headerName: '모듈 번호', width: 150},
    {field: 'ModuleAreaSPD', headerName: '모듈 위치', width: 120},
    {field: 'MDSitePlanDO', headerName: '현장 입고 계획일', type: 'date', width: 150},
]


// function CustomToolbar() {

//   return (
//     // <GridToolbarContainer>

//     // </GridToolbarContainer>
//   );
//   }
  return <div style={{height:600}}>
      <DataGridPro
        density="compact"
        pagination
        autoPageSize 
        rows={rows}
        columns={columns}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        // slots={{
        //   toolbar: CustomToolbar,
        // }}

      />
            <Button onClick={() => onModuleDataHandle(selectedRows)} style={{position:"absolute", bottom:8, right:90}}>
        적용
      </Button>
    </div>;
};